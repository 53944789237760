import TableLogo from '../assets/img/tabla-logo.png';
import tabla_ca_sa_blue from "../assets/img/tablacasalogo-blue.jpg"
import logo_blue from "../assets/img/logo_blue.png"
import logo_white_trans from "../assets/img/svg-icons/logo.svg"
import alert_new from "../assets/img/svg-icons/alert_new.svg"
//import login_logo from '../assets/img/login-logo.png'
import search_icon from '../assets/img/svg-icons/search.svg'

import chat_icon from '../assets/img/icons/chat.png'
import saved_icon from '../assets/img/icons/saved.png'
import alert_icon from '../assets/img/icons/alert.png'
import login_logo_login from '../assets/img/login_logo.png'
import hamburger_menu from '../assets/img/icons/hamburger_menu.svg'

import supplier_white_icon from '../assets/img/icons/Profiles_Suppliers_type.svg'
import qc_white_icon from '../assets/img/icons/Profiles_QCS_type.svg'
import agent_white_icon from '../assets/img/icons/profils-repres.svg'
import distributors_white_icon from '../assets/img/icons/Profiles_Distributors_type.svg'
import represntative_white_icon from '../assets/img/icons/sales_representative_default.svg'
import reprsentative_hover from '../assets/img/icons/sales_representative_hover.svg'
import vendor_white_icon from '../assets/img/icons/Profiles_Vendors_type.svg'
import e_commerce_white_icon from '../assets/img/icons/Profiles_Ecommerce_type.svg'
import copun_white_icon from '../assets/img/icons/copun-white-icon.png'
import promotion_white_icon from '../assets/img/icons/promotion-white-icon.png'

import question_icon from '../assets/img/icons/question-icon.png'
import gestion_des_stocks from '../assets/img/gestion-des-stocks.png'
import promotions_img from '../assets/img/promotions-img.png'
import coupons_img from '../assets/img/coupons-img.png'
import check_icon from '../assets/img/icons/check-icon.png'

import edit_icon from '../assets/img/svg-icons/edit_icon_sidebar.svg'
import vector_icon from '../assets/img/action-icons/vector.png'
import contact_icon from '../assets/img/action-icons/contact-icon.png'
import add_copun_icon from '../assets/img/action-icons/add-copun-icon.png'

import edit_icon_gray from '../assets/img/icons/edit-icon-gray.png'
import edit_icon_primary from '../assets/img/icons/edit-icon-primary.png'
import close_icon from '../assets/img/icons/close-icon.png'
import profile_groups from "../assets/img/svg-icons/profiles/Profile_Groups.svg"
import list_view_icon_gray from '../assets/img/icons/list-view-icon-gray.png'
import list_view_icon_primary from '../assets/img/icons/list-view-icon-primary.png'
import graph_view_gray from '../assets/img/icons/graph-view-icon-gray.png'
import graph_view_primary from '../assets/img/icons/graph-view-icon-primary.png'
import grid_view_primary from '../assets/img/icons/grid-view-primary.png'
import grid_view_secondary from '../assets/img/icons/grid-view-secondary.png'

import graph_view_rounded from '../assets/img/graph-rounded.png'
import graph_grid from '../assets/img/graph-grid.png'

import plus_icon_primary from '../assets/img/icons/plus-icon.png'
import plus_icon from '../assets/img/icons/plus-icon.png'
import plus_icon_white from '../assets/img/icons/plus-icon-wh.png'
import uploda_icon_img from '../assets/img/upload-img.png'
import stock_white_icon from '../assets/img/svg-icons/stock_sidebar.svg'
import delivery_white_icon from '../assets/img/svg-icons/delivery_sidebar.svg'
import document_white_icon from '../assets/img/svg-icons/document_sidebar.svg'
import profile_imprimer from '../assets/img/svg-icons/print.svg'
//import table_black from '../assets/img/table-black-img.jpg'
import delivery_gray_icon from '../assets/img/icons/Delivery-gray.png'
import delivery_small from '../assets/img/svg-icons/delivery-small-gray.svg'
import add_article_gray from '../assets/img/icons/add-article-gray.png'
import gray_tab_icon_location from '../assets/img/icons/warehouse_dark.svg'

import left_arrow_icon from '../assets/img/icons/left-arrow-icon.png'
import right_arrow_icon from '../assets/img/icons/right-arrow-icon.png'
import left_arrow_black from '../assets/img/icons/left-arrow-black.png'
import right_arrow_black from '../assets/img/icons/right-arrow-black.png'


import warehouse_black_icon from '../assets/img/icons/warehouse-icon-black.png'

import chair_img_1 from '../assets/img/chairs-image-1.png'
import cross_icon from '../assets/img/icons/cross-icon.png'


import print_icon from '../assets/img/print.svg'
import trash_gray from '../assets/img/trash_new.svg'
import del_truck from '../assets/img/icons/Delivery.svg'
import info_icon from '../assets/img/Info_icon.svg'
import alert_popup_icon from '../assets/img/svg-icons/alert_popup.svg'


import chair_furtif from '../assets/img/chair_medium.svg'
import documents_file from '../assets/img/icons/document_small.svg'
import new_warehouse_icon from '../assets/img/svg-icons/dashbaord-icon/new_Warehouse.svg'
import new_profile_icon from '../assets/img/svg-icons/dashbaord-icon/Profiles.svg'
import new_workorder_icon from '../assets/img/svg-icons/dashbaord-icon/work_order_new.svg'
import new_workorder_sidebar from '../assets/img/icons/workorder_sidebar_blue.svg'
import new_warehouse_sidebar from '../assets/img/icons/warehouse_sidebar_new.svg'
import new_profile_sidebar from '../assets/img/icons/profiles_sidebar_blue.svg'
import new_profile_sidebar_white from '../assets/img/icons/profiles_sidebar_white.svg'
import new_warehouse_sidebar_white from '../assets/img/icons/warehouse_sidebar_white.svg'
import new_workorder_sidebar_white from '../assets/img/icons/workorder_sidebar_white.svg'
import containers from '../assets/img/icons/containers_icon.svg'
import director_white_icon from '../assets/img/icons/profiles_directors.svg'
import ware_house_big from '../assets/img/icons/warehouse_big_new.svg'

import chair_icon_container from '../assets/img/svg-icons/furtif-chair.svg'
import add_item_icon from '../assets/img/svg-icons/Stock.svg'
import wareHouse_icon from '../assets/img/icons/warehouse_new.svg'
import component_icon from '../assets/img/icons/components.svg'
import container_small_black_icon from '../assets/img/svg-icons/container-small-black.svg'
import profile_distributor_new from '../assets/img/svg-icons/profiles/Profiles_distributors.svg'
import profile_supplier_new from '../assets/img/svg-icons/profiles/Profiles_suppliers.svg'
import profile_director_new from '../assets/img/svg-icons/profiles/Profiles_directors.svg'
import profile_vendor_new from '../assets/img/svg-icons/profiles/Profiles_vendors.svg'
import profile_agent_new from '../assets/img/svg-icons/profiles/Profiles_agents.svg'
import profile_qcs_new from '../assets/img/svg-icons/profiles/Profiles_qcs.svg'
import profile_ecommerce_new from '../assets/img/svg-icons/profiles/Profiles_ecommerce.svg'

import profile_supplier_gray from '../assets/img/svg-icons/profiles-suppliers-gray.svg'
import profile_director_gray from '../assets/img/svg-icons/profiles-directors-gray.svg'
import profile_vendor_gray from '../assets/img/svg-icons/profiles-vendors-gray.svg'
import profile_sales_agent_gray from '../assets/img/svg-icons/profiles-sales-agent-gray.svg'
import profile_qcs_gray from '../assets/img/svg-icons/profile-qcs-gray.svg'
import profile_e_commerce_gray from '../assets/img/svg-icons/profiles-e-commerce-gray.svg'
import profile_suppliers_gray from '../assets/img/svg-icons/profiles/profiles-suppliers.svg'
import edit_icon_sidebar from '../assets/img/svg-icons/work-order/sidebar-icon/edit-icon.svg'
import full_address_with_item from '../assets/img/svg-icons/work_order_Warehouse_step2.svg'
import invoice_icon_sidebar from '../assets/img/svg-icons/work-order/sidebar-icon/invoice.svg'
import document_icon_sidebar from '../assets/img/svg-icons/work-order/sidebar-icon/Document.svg'
import credit_icon_sidebar from '../assets/img/svg-icons/work-order/sidebar-icon/credit.svg'
import sav_icon_sidebar from '../assets/img/svg-icons/work-order/sidebar-icon/sav.svg'
import delivery_icon_sidebar from '../assets/img/svg-icons/work-order/sidebar-icon/Delivery.svg'

import flag_france from '../assets/img/flags/france.svg'
import Triangle from '../assets/img/svg-icons/Triangle 1.svg'
import profile_group from '../assets/img/svg-icons/profiles-group.svg'
import profile_entity from '../assets/img/icons/profile_entity.svg'
import profile_entity_small from '../assets/img/icons/profile_entity_small.svg'
import group_profile from '../assets/img/svg-icons/profils_sales_agents.svg'
import profile_group_hover from '../assets/img/icons/profile_groups_hover.svg'
import profile_group_default from '../assets/img/icons/profile_groups_default.svg'

// dashboard-main-svg-icon
// profiles-icons
// work-order-sidebar-icon-
import container_small_icon from "../assets/img/svg-icons/container-small-icon.svg"
import filter_primary from "../assets/img/svg-icons/filter-primary.svg"
import eyes_icon from "../assets/img/svg-icons/eye.svg"
import flag_icon_en from "../assets/img/action-icons/en-flag.png"
import flag_icon_es from "../assets/img/action-icons/fr-flag.png"
import flag_icon_fr from "../assets/img/action-icons/es-flag.png"
import analystic from "../assets/img/Stock.svg"
import analystic_white from "../assets/img/Stock_white.svg"

// Flag images
import flag_belgium from "../assets/img/flags/belgium.png"
import flag_costa from "../assets/img/flags/costa-rica.png"
import flag_germany from "../assets/img/flags/germany.png"
import flag_italy from "../assets/img/flags/italy.png"
import flag_luxembourg from "../assets/img/flags/luxembourg.png"
import flag_portugal from "../assets/img/flags/portugal.png"
import flag_spain from "../assets/img/flags/spain.png"
import flag_switzerland from "../assets/img/flags/switzerland.png"
import flag_united_kingdom from "../assets/img/flags/united-kingdom.png"
import flag_united_states from "../assets/img/flags/united-states.png"
import icon_calculator_primary from "../assets/img/icons/calculator-icon-primary.svg"
import bar_chart_icon_primary from "../assets/img/icons/bar-chart-icon-primary.svg"
import line_chart_icon_primary from "../assets/img/icons/line-chart-icon-primary.png"
import down_arrow_lite_gray from "../assets/img/down-arrow-lite-gray.svg"
import search_icon_black_small from "../assets/img/icons/search-icon-black-small.svg"
import tarifaire_groupes from "../assets/img/svg-icons/profiles/tarifaire-groupes.svg"
import tarifs_groupes from "../assets/img/svg-icons/profiles/tarifs.svg"
import document_icon from "../assets/img/svg-icons/Document.svg"


export let Image = {
    eyes_icon, flag_icon_fr, flag_icon_es, icon_calculator_primary,
    profile_groups, bar_chart_icon_primary, line_chart_icon_primary,
    profile_supplier_new, down_arrow_lite_gray, search_icon_black_small,
    profile_director_new,
    profile_vendor_new,
    profile_agent_new,
    profile_qcs_new,
    profile_ecommerce_new,
    profile_distributor_new,
    wareHouse_icon,
    component_icon,
    containers,
    new_profile_sidebar_white,
    new_warehouse_sidebar_white,
    new_workorder_sidebar_white,
    new_workorder_sidebar,
    new_warehouse_sidebar,
    new_profile_sidebar,
    new_warehouse_icon,
    new_profile_icon,
    new_workorder_icon,
    TableLogo,
    tabla_ca_sa_blue,
    logo_blue,
    logo_white_trans,
    search_icon,
    chat_icon,
    saved_icon,
    alert_icon,
    login_logo_login,
    hamburger_menu,
    supplier_white_icon,
    qc_white_icon,
    agent_white_icon,
    distributors_white_icon,
    represntative_white_icon,
    profile_group_hover,
    profile_group_default,
    reprsentative_hover,
    vendor_white_icon,
    e_commerce_white_icon,
    copun_white_icon,
    promotion_white_icon,
    question_icon,
    gestion_des_stocks,
    promotions_img,
    coupons_img,
    check_icon,
    edit_icon,
    vector_icon,
    contact_icon,
    add_copun_icon,
    edit_icon_gray,
    edit_icon_primary,
    close_icon,
    list_view_icon_primary,
    graph_view_gray,
    list_view_icon_gray,
    graph_view_primary,
    graph_view_rounded,
    graph_grid,
    plus_icon_primary,
    uploda_icon_img,
    stock_white_icon,
    delivery_white_icon,
    document_white_icon,
    delivery_gray_icon,
    add_article_gray,
    delivery_small,
    gray_tab_icon_location,
    left_arrow_icon,
    right_arrow_icon,
    warehouse_black_icon,
    grid_view_primary,
    grid_view_secondary,
    chair_img_1,
    left_arrow_black,
    right_arrow_black,
    plus_icon,
    plus_icon_white,
    cross_icon,
    print_icon,
    trash_gray,
    del_truck,
    info_icon,
    chair_furtif,
    documents_file,
    director_white_icon,
    chair_icon_container,
    add_item_icon,
    container_small_black_icon,
    edit_icon_sidebar,
    invoice_icon_sidebar,
    document_icon_sidebar,
    credit_icon_sidebar,
    sav_icon_sidebar,
    delivery_icon_sidebar,
    full_address_with_item,
    ware_house_big,
    profile_supplier_gray,
    profile_director_gray,
    profile_vendor_gray,
    profile_sales_agent_gray,
    profile_qcs_gray,
    profile_e_commerce_gray,
    flag_france,
    Triangle,
    profile_suppliers_gray,
    profile_group,
    alert_new,
    alert_popup_icon,
    container_small_icon,
    filter_primary,
    profile_entity,
    profile_entity_small,
    group_profile,
    flag_belgium,
    flag_costa,
    flag_germany,
    flag_italy,
    flag_luxembourg,
    flag_portugal,
    flag_spain,
    flag_switzerland,
    flag_united_kingdom,
    flag_united_states,
    profile_imprimer,
    flag_icon_en,
    analystic,
    analystic_white,
    tarifaire_groupes,
    tarifs_groupes,
    document_icon,
    
};
