import React, {Component} from 'react';
import {getTarifFaire} from "../controller/API/profileApi";
import {Select, Spin} from "antd";
import {Translation} from "react-i18next";

const {Option} = Select;


class TariffGroupFilter extends Component {
  fetch = (params = {}) => {
    this.setState({loading: true});
    getTarifFaire(params)
      .then(values => {
        this.setState({values: values.results, loading: false})
      })
  };

  handleChange = (value) => {
    this.setState({value});
    this.props.onChange(value.key)
  };


  constructor(props) {
    super(props);
    this.state = {
      values: [],
      loading: false,
      value: this.props.value || [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.value) {
        return {value: []};
    } else if (typeof nextProps.value === "object") {
        return {value: nextProps.value}
    } else return null;
}


  componentDidMount() {
    if (this.props.value)
      this.handleChange(this.props.value)
  }

  render() {
    const {values, loading, value} = this.state;
    return (
      <Translation>
        {(t, {i18n}) =>
            <Select labelInValue
                    value={value}
                    onSearch={(value) => this.fetch({search: value})}
                    showSearch
                    showAction={["focus", "click"]}
                    optionFilterProp="children"
                    onFocus={() => this.fetch()}
                    notFoundContent={loading ? <Spin size="small"/> : null}
                    onChange={(val) => this.handleChange(val)}
                    placeholder={t('select')}>
              {values.map((d, index) =>
                  <Option key={`client_type_${index}`} value={d.id}>{d.name}</Option>)
              }
            </Select>
        }
      </Translation>
    );
  }
}

export default TariffGroupFilter;
