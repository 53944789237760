import React, {Component} from 'react';
import {Button, Form, InputNumber, message, Modal} from "antd";
// import {variantUpdate} from "../../../controller/API/itemApi";
import {withTranslation} from "react-i18next";
import {Image as Images} from "../../../Images";
import {connect} from "react-redux";
import {mapWarehouseDispatchToProps, mapWarehouseStateToProps} from "../../../../redux/Actions/WarehouseAction";

import { variantUpdate, warehouseComponentInventoryGet, warehouseComponentInventoryUpdate } from '../../../../controller/API/itemApi';

class UpdateComponentStockForm extends Component {

    state = {
        warehouseComponentInventory: []
    }

    componentDidMount() {
        this.setState({
            warehouseComponentInventory: this.props.item.warehouse_inventory_object
        })
        this.fetchComponentInventory(this.props.item.id)
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {item} = this.props;
                if (item) {
                    Promise.allSettled(
                        Object.entries(values).map((
                            [id, in_stock], idx) =>
                            warehouseComponentInventoryUpdate(id, { in_stock })
                        )
                    ).then(() => {
                        message.success("product updated successfully.");
                        this.props.onClose(true);
                    }).catch(err => {
                        this.handleError(err)}
                    )
                }
            }
        })
    };

    handleError = (err) => {
        if (err.response) {
            Object.keys(err.response.data).forEach((i) => {
                message.error(`${i}:${err.response.data[i]}`)
            })
        } else
            message.error("Something went wrong.")
    };

    fetchComponentInventory = async (id) => {
        warehouseComponentInventoryGet({component: id}).then(res => {
            this.setState({
                warehouseVariantInventory: res.results,
            })
        })
    }

    render() {
        const {t, warehouse_id_mapping} = this.props;
        const {getFieldDecorator} = this.props.form;
        const { warehouseComponentInventory } = this.state;
        return (
            <Modal
                style={{top: '26%'}}
                title={t('stock_warehouse')}
                visible={this.props.visible}
                onOk={this.handleSubmit}
                onCancel={() => this.props.onClose(false)}
                width="32%"
                okText={t('update_caps')}
                cancelText={t('cancel')}
                className="confirm-modal-main"
            >
                <div className="row mx-0 py-4 px-3">
                    <Form onSubmit={this.handleSubmit} className="main-form w-100">
                        <div className="col-12">
                        {
                                warehouseComponentInventory.map(inventory =>
                                    <Form.Item label={warehouse_id_mapping[inventory.warehouse].name}>
                                        {getFieldDecorator(`${inventory.id}`, {
                                            initialValue: inventory.in_stock,
                                            rules: [{required: true, message: t('please_input') + t('in_stock') + '!'}],
                                        })(<InputNumber autoFocus placeholder={t('enter')} />)}
                                    </Form.Item>
                                )
                            }
                        </div>
                    </Form>
                </div>
            </Modal>
        );
    }
}


export const UpdateComponentStock = (Form.create({
    mapPropsToFields(props) {
        if (props.item) {
            const {in_stock} = props.item;
            return {
                in_stock: Form.createFormField({
                    value: in_stock
                }),
            }
        }
    }
})(withTranslation('common')(connect(mapWarehouseStateToProps, mapWarehouseDispatchToProps)(UpdateComponentStockForm))));
