import {combineReducers} from "redux";
import {distributorProfileReducer} from "./DistributorProfileReducer";
import {itemReducer, itemToInvoiceReducer} from "./ItemReducer";
import workOrderReducer from "./WorkorderReducer";
import warehouseReducer from "./WarehouseReducer";

const rootReducer = combineReducers({
    distributor: distributorProfileReducer,
    item: itemReducer,
    current_workorder: workOrderReducer,
    invoiceItem: itemToInvoiceReducer,
    warehouse: warehouseReducer
});


export default rootReducer
