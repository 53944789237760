
import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface WorkOrderState {
  data: any;
}

const initialState: WorkOrderState = {
  data: null,
};

const workOrderSlice = createSlice({
  name: 'workOrder',
  initialState,
  reducers: {
    workOrderOneGet: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
  },
});

export const { workOrderOneGet } = workOrderSlice.actions;
export default workOrderSlice.reducer;
