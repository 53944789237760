export const country = [
  {
    "en": "Afghanistan",
    "fr": "Afghanistan"
  },
  {
    "en": "Albania",
    "fr": "Albanie"
  },
  {
    "en": "Algeria",
    "fr": "Algérie"
  },
  {
    "en": "Andorra",
    "fr": "Andorre"
  },
  {
    "en": "Angola",
    "fr": "Angola"
  },
  {
    "en": "Antigua and Barbuda",
    "fr": "Antigua-et-Barbuda"
  },
  {
    "en": "Argentina",
    "fr": "Argentine"
  },
  {
    "en": "Armenia",
    "fr": "Arménie"
  },
  {
    "en": "Australia",
    "fr": "Australie"
  },
  {
    "en": "Austria",
    "fr": "Autriche"
  },
  {
    "en": "Azerbaijan",
    "fr": "Azerbaïdjan"
  },
  {
    "en": "Bahamas",
    "fr": "Bahamas"
  },
  {
    "en": "Bahrain",
    "fr": "Bahreïn"
  },
  {
    "en": "Bangladesh",
    "fr": "Bangladesh"
  },
  {
    "en": "Barbados",
    "fr": "Barbade"
  },
  {
    "en": "Belarus",
    "fr": "Biélorussie"
  },
  {
    "en": "Belgium",
    "fr": "Belgique"
  },
  {
    "en": "Belize",
    "fr": "Belize"
  },
  {
    "en": "Benin",
    "fr": "Bénin"
  },
  {
    "en": "Bhutan",
    "fr": "Bhoutan"
  },
  {
    "en": "Bolivia",
    "fr": "Bolivie"
  },
  {
    "en": "Bosnia and Herzegovina",
    "fr": "Bosnie-Herzégovine"
  },
  {
    "en": "Botswana",
    "fr": "Botswana"
  },
  {
    "en": "Brazil",
    "fr": "Brésil"
  },
  {
    "en": "Brunei",
    "fr": "Brunei"
  },
  {
    "en": "Bulgaria",
    "fr": "Bulgarie"
  },
  {
    "en": "Burkina Faso",
    "fr": "Burkina Faso"
  },
  {
    "en": "Burundi",
    "fr": "Burundi"
  },
  {
    "en": "Cambodia",
    "fr": "Cambodge"
  },
  {
    "en": "Cameroon",
    "fr": "Cameroun"
  },
  {
    "en": "Canada",
    "fr": "Canada"
  },
  {
    "en": "Cape Verde",
    "fr": "Cap-Vert"
  },
  {
    "en": "Central African Republic",
    "fr": "République centrafricaine"
  },
  {
    "en": "Chad",
    "fr": "Tchad"
  },
  {
    "en": "Chile",
    "fr": "Chili"
  },
  {
    "en": "China",
    "fr": "Chine"
  },
  {
    "en": "Colombia",
    "fr": "Colombie"
  },
  {
    "en": "Comoros",
    "fr": "Comores"
  },
  {
    "en": "Costa Rica",
    "fr": "Costa Rica"
  },
  {
    "en": "Côte d’Ivoire",
    "fr": "Côte d’Ivoire"
  },
  {
    "en": "Croatia",
    "fr": "Croatie"
  },
  {
    "en": "Cuba",
    "fr": "Cuba"
  },
  {
    "en": "Cyprus",
    "fr": "Chypre"
  },
  {
    "en": "Czech Republic",
    "fr": "République tchèque"
  },
  {
    "en": "Democratic Republic of the Congo",
    "fr": "République démocratique du Congo"
  },
  {
    "en": "Denmark",
    "fr": "Danemark"
  },
  {
    "en": "Djibouti",
    "fr": "Djibouti"
  },
  {
    "en": "Dominica",
    "fr": "Dominique"
  },
  {
    "en": "Dominican Republic",
    "fr": "République dominicaine"
  },
  {
    "en": "East Timor",
    "fr": "Timor oriental"
  },
  {
    "en": "Ecuador",
    "fr": "Équateur"
  },
  {
    "en": "Egypt",
    "fr": "Égypte"
  },
  {
    "en": "El Salvador",
    "fr": "Salvador"
  },
  {
    "en": "Equatorial Guinea",
    "fr": "Guinée équatoriale"
  },
  {
    "en": "Eritrea",
    "fr": "Érythrée"
  },
  {
    "en": "Estonia",
    "fr": "Estonie"
  },
  {
    "en": "Ethiopia",
    "fr": "Éthiopie"
  },
  {
    "en": "Fiji",
    "fr": "Fidji"
  },
  {
    "en": "Finland",
    "fr": "Finlande"
  },
  {
    "en": "France",
    "fr": "France"
  },
  {
    "en": "Gabon",
    "fr": "Gabon"
  },
  {
    "en": "Gambia",
    "fr": "Gambie"
  },
  {
    "en": "Georgia",
    "fr": "Géorgie"
  },
  {
    "en": "Germany",
    "fr": "Allemagne"
  },
  {
    "en": "Ghana",
    "fr": "Ghana"
  },
  {
    "en": "Greece",
    "fr": "Grèce"
  },
  {
    "en": "Grenada",
    "fr": "Grenade"
  },
  {
    "en": "Guatemala",
    "fr": "Guatemala"
  },
  {
    "en": "Guinea",
    "fr": "Guinée"
  },
  {
    "en": "Guinea-Bissau",
    "fr": "Guinée-Bissau"
  },
  {
    "en": "Guyana",
    "fr": "Guyane"
  },
  {
    "en": "Haiti",
    "fr": "Haïti"
  },
  {
    "en": "Honduras",
    "fr": "Honduras"
  },
  {
    "en": "Hungary",
    "fr": "Hongrie"
  },
  {
    "en": "Iceland",
    "fr": "Islande"
  },
  {
    "en": "India",
    "fr": "Inde"
  },
  {
    "en": "Indonesia",
    "fr": "Indonésie"
  },
  {
    "en": "Iran",
    "fr": "Iran"
  },
  {
    "en": "Iraq",
    "fr": "Irak"
  },
  {
    "en": "Ireland",
    "fr": "Irlande"
  },
  {
    "en": "Israel",
    "fr": "Israël"
  },
  {
    "en": "Italy",
    "fr": "Italie"
  },
  {
    "en": "Jamaica",
    "fr": "Jamaïque"
  },
  {
    "en": "Japan",
    "fr": "Japon"
  },
  {
    "en": "Jordan",
    "fr": "Jordan"
  },
  {
    "en": "Kazakhstan",
    "fr": "Kazakhstan"
  },
  {
    "en": "Kenya",
    "fr": "Kenya"
  },
  {
    "en": "Kiribati",
    "fr": "Kiribati"
  },
  {
    "en": "Kuwait",
    "fr": "Koweït"
  },
  {
    "en": "Kyrgyzstan",
    "fr": "Kirghizistan"
  },
  {
    "en": "Laos",
    "fr": "Laos"
  },
  {
    "en": "Latvia",
    "fr": "Lettonie"
  },
  {
    "en": "Lebanon",
    "fr": "Liban"
  },
  {
    "en": "Lesotho",
    "fr": "Lesotho"
  },
  {
    "en": "Liberia",
    "fr": "Liberia"
  },
  {
    "en": "Libya",
    "fr": "Libye"
  },
  {
    "en": "Liechtenstein",
    "fr": "Liechtenstein"
  },
  {
    "en": "Lithuania",
    "fr": "Lituanie"
  },
  {
    "en": "Luxembourg",
    "fr": "Luxembourg"
  },
  {
    "en": "Madagascar",
    "fr": "Madagascar"
  },
  {
    "en": "Malawi",
    "fr": "Malawi"
  },
  {
    "en": "Malaysia",
    "fr": "Malaisie"
  },
  {
    "en": "Maldives",
    "fr": "Maldives"
  },
  {
    "en": "Mali",
    "fr": "Mali"
  },
  {
    "en": "Malta",
    "fr": "Malte"
  },
  {
    "en": "Marshall Islands",
    "fr": "Îles Marshall"
  },
  {
    "en": "Mauritania",
    "fr": "Mauritanie"
  },
  {
    "en": "Mauritius",
    "fr": "Maurice"
  },
  {
    "en": "Mexico",
    "fr": "Mexique"
  },
  {
    "en": "Micronesia",
    "fr": "Micronésie"
  },
  {
    "en": "Moldova",
    "fr": "Moldavie"
  },
  {
    "en": "Monaco",
    "fr": "Monaco"
  },
  {
    "en": "Mongolia",
    "fr": "Mongolie"
  },
  {
    "en": "Montenegro",
    "fr": "Monténégro"
  },
  {
    "en": "Morocco",
    "fr": "Maroc"
  },
  {
    "en": "Mozambique",
    "fr": "Mozambique"
  },
  {
    "en": "Myanmar",
    "fr": "Birmanie"
  },
  {
    "en": "Namibia",
    "fr": "Namibie"
  },
  {
    "en": "Nauru",
    "fr": "Nauru"
  },
  {
    "en": "Nepal",
    "fr": "Népal"
  },
  {
    "en": "Netherlands",
    "fr": "Pays-Bas"
  },
  {
    "en": "New Zealand",
    "fr": "Nouvelle-Zélande"
  },
  {
    "en": "Nicaragua",
    "fr": "Nicaragua"
  },
  {
    "en": "Niger",
    "fr": "Niger"
  },
  {
    "en": "Nigeria",
    "fr": "Nigeria"
  },
  {
    "en": "North Korea",
    "fr": "Corée du Nord"
  },
  {
    "en": "Norway",
    "fr": "Norvège"
  },
  {
    "en": "Oman",
    "fr": "Oman"
  },
  {
    "en": "Pakistan",
    "fr": "Pakistan"
  },
  {
    "en": "Palau",
    "fr": "Palau"
  },
  {
    "en": "Panama",
    "fr": "Panama"
  },
  {
    "en": "Papua New Guinea",
    "fr": "Papouasie-Nouvelle-Guinée"
  },
  {
    "en": "Paraguay",
    "fr": "Paraguay"
  },
  {
    "en": "Peru",
    "fr": "Pérou"
  },
  {
    "en": "Philippines",
    "fr": "Philippines"
  },
  {
    "en": "Poland",
    "fr": "Pologne"
  },
  {
    "en": "Portugal",
    "fr": "Portugal"
  },
  {
    "en": "Qatar",
    "fr": "Qatar"
  },
  {
    "en": "Republic of the Congo",
    "fr": "République du Congo"
  },
  {
    "en": "Republic of Macedonia",
    "fr": "Macédoine"
  },
  {
    "en": "Romania",
    "fr": "Roumanie"
  },
  {
    "en": "Russia",
    "fr": "Russie"
  },
  {
    "en": "Rwanda",
    "fr": "Rwanda"
  },
  {
    "en": "Saint Kitts and Nevis",
    "fr": "Saint-Christophe-et-Niévès"
  },
  {
    "en": "Saint Lucia",
    "fr": "Sainte-Lucie"
  },
  {
    "en": "Saint Vincent and the Grenadines",
    "fr": "Saint-Vincent-et-les-Grenadines"
  },
  {
    "en": "Samoa",
    "fr": "Samoa"
  },
  {
    "en": "San Marino",
    "fr": "Saint-Marin"
  },
  {
    "en": "Sao Tome and Principe",
    "fr": "São Tomé-et-Principe"
  },
  {
    "en": "Saudi Arabia",
    "fr": "Arabie saoudite"
  },
  {
    "en": "Senegal",
    "fr": "Sénégal"
  },
  {
    "en": "Serbia",
    "fr": "Serbie"
  },
  {
    "en": "Seychelles",
    "fr": "Seychelles"
  },
  {
    "en": "Sierra Leone",
    "fr": "Sierra Leone"
  },
  {
    "en": "Singapore",
    "fr": "Singapour"
  },
  {
    "en": "Slovakia",
    "fr": "Slovaquie"
  },
  {
    "en": "Slovenia",
    "fr": "Slovénie"
  },
  {
    "en": "Solomon Islands",
    "fr": "Îles Salomon"
  },
  {
    "en": "Somalia",
    "fr": "Somalie"
  },
  {
    "en": "South Africa",
    "fr": "Afrique du Sud"
  },
  {
    "en": "South Korea",
    "fr": "Corée du Sud"
  },
  {
    "en": "South Sudan",
    "fr": "Soudan du Sud"
  },
  {
    "en": "Spain",
    "fr": "Espagne"
  },
  {
    "en": "Sri Lanka",
    "fr": "Sri Lanka"
  },
  {
    "en": "Sudan",
    "fr": "Soudan"
  },
  {
    "en": "Suriname",
    "fr": "Suriname"
  },
  {
    "en": "Swaziland",
    "fr": "Swaziland"
  },
  {
    "en": "Sweden",
    "fr": "Suède"
  },
  {
    "en": "Switzerland",
    "fr": "Suisse"
  },
  {
    "en": "Syria",
    "fr": "Syrie"
  },
  {
    "en": "Tajikistan",
    "fr": "Tadjikistan"
  },
  {
    "en": "Tanzania",
    "fr": "Tanzanie"
  },
  {
    "en": "Thailand",
    "fr": "Thaïlande"
  },
  {
    "en": "Togo",
    "fr": "Togo"
  },
  {
    "en": "Tonga",
    "fr": "Tonga"
  },
  {
    "en": "Trinidad and Tobago",
    "fr": "Trinité-et-Tobago"
  },
  {
    "en": "Tunisia",
    "fr": "Tunisie"
  },
  {
    "en": "Turkey",
    "fr": "Turquie"
  },
  {
    "en": "Turkmenistan",
    "fr": "Turkménistan"
  },
  {
    "en": "Tuvalu",
    "fr": "Tuvalu"
  },
  {
    "en": "Uganda",
    "fr": "Ouganda"
  },
  {
    "en": "Ukraine",
    "fr": "Ukraine"
  },
  {
    "en": "United Arab Emirates",
    "fr": "Émirats arabes unis"
  },
  {
    "en": "United Kingdom",
    "fr": "Royaume-Uni"
  },
  {
    "en": "United States of America",
    "fr": "États-Unis"
  },
  {
    "en": "Uruguay",
    "fr": "Uruguay"
  },
  {
    "en": "Uzbekistan",
    "fr": "Ouzbékistan"
  },
  {
    "en": "Vanuatu",
    "fr": "Vanuatu"
  },
  {
    "en": "Venezuela",
    "fr": "Venezuela"
  },
  {
    "en": "Vietnam",
    "fr": "Viêt Nam"
  },
  {
    "en": "Yemen",
    "fr": "Yémen"
  },
  {
    "en": "Zambia",
    "fr": "Zambie"
  },
  {
    "en": "Zimbabwe",
    "fr": "Zimbabwe"
  }
]
