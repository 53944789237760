import { warehouseAllGetSuccess } from "../Reducer/WarehouseReducer";
import { AppDispatch, RootState } from "../store";

export const WAREHOUSE_ALL_GET = 'WAREHOUSE_ALL_GET';
export const WAREHOUSE_ALL_GET_SUCCESS = 'WAREHOUSE_ALL_GET_SUCCESS';

export const mapWarehouseStateToProps = (state: RootState) => {
    return {
      warehouses: state.warehouse?.warehouses,
      warehouse_id_mapping: state.warehouse?.warehouse_id_mapping,
    };
  };

export const mapWarehouseDispatchToProps = (dispatch: AppDispatch) => {
    return {
        getAllWarehouses: (warehouses: any) => dispatch(warehouseAllGetSuccess(warehouses)),
    };
  };
