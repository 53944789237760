import React, { Component } from 'react';
import { Button, Drawer, Input, message, Select, Spin } from 'antd';
import { Image as Images } from "../../../Images";
import { withTranslation } from "react-i18next";
import { containerItemAdd, itemCategoryGet, productGet } from "../../../../controller/API/itemApi";
import { SingleItemCardComponent } from '../all-items/SingleItemCardComponent';

const { Search } = Input;

const { Option } = Select;

class ComponentItemListDrawer extends Component {

    state = {
        items: [],
        category: [],
        params: { category_id: null },
        fetched: false,
        loading: true,
        totalItems: this.props.totalItems,
        selected_items: [],
        selectedCategory: "",
        mainProduct: this.props.product
    };

    handleSubmit = (product, selected,) => {
        const parentProduct = this.state.mainProduct.filter((item) => item.id === product.variant[selected].id)
        const innerProduct = this.state.selected_items.filter((item) => item.id === product.variant[selected].id);
        if (parentProduct.length === 0 && innerProduct.length === 0) {
            const { selected_items } = this.state;
            selected_items.push(product.variant[selected])
            this.setState({ selected_items, selectedCategory: product })
            this.setState({ buttonLoading: false })

            message.success("produit ajouté avec succès.");
        }
        else {
            message.error("Produit déjà ajouté")
        }

    };

    handleFilter = (value) => {
        let { params } = this.state;
        params["category_id"] = value;
        this.setState({ params });
        this.props.fetch(params);
    };


    render() {
        const { t, container, item, category } = this.props;
        const { totalItems, selected_items } = this.state;
        const items = item.filter(o => o.variant.length > 0)
        return (
            <Drawer
                title={t('add_article')}
                placement="right"
                closable={false}
                onClose={this.props.onClose}
                visible={this.props.visible}
                width="81.3%"
                destroyOnClose={true}
            >
                <div className="row mx-0">
                    <Button onClick={()=>{
                        this.props.fetch()
                        this.props.onClose()}}
                        className="close-header-icon w-auto flex-align-center-center px-3 position-absolute p-0 border-0">
                        <img src={Images.close_icon} alt="close icon" className="img-fluid" />
                        <div>{t('close_btntext')}</div>
                    </Button>
                    <div className="col-12 clearfix list-main-drawer">
                        <div className="row mx-0 add-item-list-row-header">
                            <div className="col-12 p-0">
                                <div className="row mx-0 list-item-second-header pt-0 align-items-center w-100">
                                    <div className="col-sm-12 col-lg-4 col-md-12 col-12">
                                    </div>
                                    <div className="col-12 col-sm-12 col-lg-8 col-md-12 pr-0">
                                        <div className="row mx-0">
                                            <Search
                                                placeholder="Rechercher des articles"
                                                onSearch={value => this.props.fetch({ "search": value })}
                                                style={{ width: '71%' }}
                                            />
                                            <Select placeholder="FILTRE
                                                    PRODUITS" className="select-div-item" onChange={this.handleFilter}>
                                                <Option key={`category_00`} value={null}>ALL</Option>
                                                {category?.map((d, index) =>
                                                    <Option key={`category_${index}`} value={d.id}>{d.name}</Option>
                                                )}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mx-0 list-item-card-row">
                            {items.length > 0 && items.map((d, index) =>
                                <SingleItemCardComponent key={`product_card_${index}`} product={d}
                                    container={true}
                                    submit={(selected) => this.handleSubmit(d, selected)} />
                            )}
                        </div>
                        {/*<AddItemListView/>*/}
                    </div>
                    <div className="drawer-footer w-100 position-absolute text-right bg-white px-3 py-2 border-top">
                        <Button onClick={() => {

                            this.props.selectedItem(this.state.selected_items, this.state.selectedCategory.category)
                        this.props.fetch()

                            this.props.onClose();
                        }} type="primary">
                            VALIDER
                        </Button>
                    </div>
                </div>
            </Drawer>
        );
    }
}

export default (withTranslation('common')(ComponentItemListDrawer));
