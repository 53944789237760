import React, { Component } from 'react';
import { Button, Form, Input, InputNumber, message, Select, Spin } from 'antd';
import { createTariff, EditTariff, getTariff, getTarifFaire, getTariffOne, groupPost, groupUpdate, RemoveComponentTariff } from "../../../controller/API/profileApi";
import { Image as Images } from "../../Images";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { showErrors } from "../../../controller/utils";
import { methods } from "../../../controller/Global";
import ConfirmPopup from "../../modal/ConfirmPopup";
import { history } from "../../../controller/history";
import { reverse } from "named-urls";
import { routes } from "../../../controller/routes";
import { CreateTarifs } from '../../drawers/TarifsGroupDrawer';
import { ComponentGet, getSkudetail, itemCategoryGet, itemCategoryGetID, productGet, productGetID, variantAdd, variantGet } from '../../../controller/API/itemApi';
import TariffGroupFilter from '../../../filters/TariffGroupFilter';
import ItemCategoryFilter from '../../../filters/itemCategoryFilter'
import ProductFilter from '../../../filters/productFilter';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import { findIndex } from 'lodash';
import ItemSkuFilter from '../../../filters/ItemSkuFilter';
// import TarifCategoryFilter from '../../../filters/TarifCategoryFilter';

class TarifsGroupProfileFormComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmGroup: false,
            visibleTarifsDrawer: false,
            categoryValue: null,
            loading: false,
            all_category: [],
            all_product: [],
            productdata: null,
            all_tariff: [],
            tariffData: null,
            component: [],
            group: {},
            cardData: null,
            drawer_component_data: [],
            groupid: null,
            all_varient: [],
            select_varient_info: null,
            familyID: null,
            categoryID: null,
            sku:null,
            supplement:null,
        };
    }

    handleSubmit = () => {
        this.props.form.validateFields((err, values) => {

            if (!err) {
                const { match } = this.props;
                if (match.params.method === methods.edit) {
                    values.component = this.state.component.map((val) => val.id)
                    values.category = this.state.categoryID
                    values.tariff_group = values?.tariff_group?.key || values.tariff_group
                    values.family = this.state.familyID
                    values.unique_price = values.unique_price ? values.unique_price : null
                    values.group_rate = values.group_rate ? values.group_rate : null
                    values.group_quantity = values.group_quantity ? values.group_quantity : null
                    EditTariff(match.params.id, values).then((response) => {
                        this.confirmGroupVisible(true, null);
                    }).catch((err) => { console.log(err) })
                } else {
                    values.component = this.state.component.map((val) => val.id)
                    values.family = this.state.familyID
                    values.category = this.state.categoryID

                    createTariff(values).then((data) =>
                        this.confirmGroupVisible(true, data.id)
                    );
                }

            }
        })
    };

    fetchCategory = (params = {}, id) => {
        itemCategoryGetID(params, id).then((value) => {
            this.props.form.setFieldsValue({
                "category": value.name,
            });
            this.setState({ categoryID: value.id });

        });
    };


    handleCategory = (categoryValue) => {
        this.setState({ categoryValue });
    };

    fetchVarient = (params = {}) => {
        variantGet(params)
            .then(response => {
                this.setState({ all_varient: response.items, loading: false })
            })
    };

    fetchProduct = (params = {}, id) => {
        productGetID(params, id)
            .then(response => {
                this.props.form.setFieldsValue({
                    "family": response.data.name,
                });
                this.setState({ familyID: response.data.id });
            })
    };

    productChange = (productdata) => {
        this.setState({ productdata })
    }


    handleVarient = (varientValue) => {
        this.setState({ varientValue })
        const varient_values = this.state.all_varient.filter(item => item.id === varientValue.key)
        this.setState({ select_varient_info: varient_values[0] })
        this.props.form.setFieldsValue({
            "price": varient_values[0]?.price,
        });
        this.fetchCategory({}, varient_values[0].product.category.id)
        this.fetchProduct({}, varient_values[0].product.id)
    }

    fetchTariff = (params = {}) => {
        getTarifFaire(params).then((value) => {
            this.setState({ all_tariff: value.results, loading: false });
        })
    };

    tariffChange = (tariffData) => {
        this.setState({ tariffData })
    }


    confirmGroupVisible = (visible, id) => {
        this.setState({ confirmGroup: visible })
        this.setState({ groupid: id })
    };
    visibleTarifsCreateDrawer = (visible) => {
        this.setState({
            visibleTarifsDrawer: visible
        })
    };
    componentStateUpdate = (data) => {
        const item = this?.state?.component?.find((obj) => obj.id == data.id)
        if (item) {
            const index = this?.state?.component?.findIndex((obj) => obj.id === data.id)
            const component = [...this.state.component]
            component[index] = data
            this.setState({ component })
        } else {
            this.setState({ component: this.state.component.concat(data) })
        }
    }
    fetch = (id) => {
        return getTariffOne(id).then(response => {
            this.getVarientDetail(response.variant_sku)
            this.setState({
                component: response.component,
                group: response,
                supplement:response.additional_price
            })
        }).catch(err => message.error(err))
    };
    componentDidMount() {
        const { match } = this.props;
        // this.fetchVarient()
        ComponentGet()
            .then(res => {
                this.setState({ drawer_component_data: res.items })

            })
        if (match.params.method === methods.edit) {
            this.fetch(match.params.id)
        }
    }
    handleCardEdit = (val, edit) => {
        this.visibleTarifsCreateDrawer(true)
        this.setState({ cardData: val })
    }
    HandleComponentDrawer = () => {
        this.visibleTarifsCreateDrawer(true)
        this.setState({ cardData: null })
        this.setState({sku:this.props.form.getFieldValue("variant_sku")})
    }
    handleCardDelete = (val) => {
        const component = this.state?.component;
        var index = component?.findIndex((o) => o.id === val)
        if (index !== -1) {
            component.splice(index, 1);
            this.setState({ component })
        }
        RemoveComponentTariff(val).then((res) => { console.log(res) }).catch((err) => {
            message.err(err)
        })
    }
    getVarientDetail=(val)=>{
        if(val){
            let params={
                sku:val
            }
            getSkudetail(params).then(res=>{
                
                if(res?.data?.message){
                    this.props.form.setFields({"variant_sku":{value: val, errors:[new Error(res?.data?.message)]}})
                    this.props.form.setFieldsValue({
                        "price": null,
                        "family": null,
                        "category": null,
                    });
                }else{
                    this.setState({"familyID":res.data.family.id,"categoryID":res.data.category.id,"varientID":res.data.id})
                    this.props.form.setFieldsValue({
                        "price": res?.data?.price,
                        "family": {key: res.data.family.id,label:res.data.family.label},
                        "category": {key: res.data.category.id,label:res.data.category.label},
                    });
                }
            }).catch(err=>{
                
                console.log(err,"err")
    
            })
        }
    }
    render() {
        const { t, group } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { params } = this.props.match;
        const { confirmGroup, categoryValue, loading, all_category, all_product, productdata, tariffData, all_tariff } = this.state;
        return (
            <React.Fragment>
                <div className="row mx-0 steps-main-row">
                    <div className="col-12">
                        <h6 className="all-heading-div text-uppercase font-weight-bold mb-4 pb-2">{t('tariff_information')}</h6>
                    </div>
                    <div className="col-12">
                        <Form className="main-form">
                            <div className="row">
                                <div className="col-sm-12 col-12 col-md-12 col-lg-6">
                                    <Form.Item label={t('name')}>
                                        {getFieldDecorator('name', {
                                            rules: [{ required: true, message: t('please_input') }],
                                        })(
                                            <Input autoFocus placeholder={t('nom')} />,
                                        )}
                                    </Form.Item>
                                </div>
                                <div className="col-sm-12 col-12 col-md-12 col-lg-6">
                                    <Form.Item label={t('tariff_group')}>

                                        {getFieldDecorator('tariff_group', {
                                            rules: [{ required: true, message: t('please_input') }],
                                        })(
                                            <TariffGroupFilter />
                                        )}
                                    </Form.Item>

                                </div>
                                <div className="col-sm-12 col-12 col-md-12 col-lg-6">
                                <Form.Item  label={t('product_ref')}>
                                        {getFieldDecorator('variant_sku', {
                                            rules: [{ required: true, message: t('please_input') }],
                                        })(
                                            <Input autoFocus placeholder={t('variant_sku')} onBlur={(val)=>this.getVarientDetail(val.target.value)}/>,
                                        )}
                                    </Form.Item>
                                </div>

                                <div className="col-sm-12 col-12 col-md-12 col-lg-6">
                                    <Form.Item label={t('family')}>
                                        {getFieldDecorator('family', {
                                            rules: [{ required: true, message: t('please_input') }],
                                        })(
                                            <ProductFilter varient_data={this.state.familyID} />
                                        )}
                                    </Form.Item>
                                </div>
                                <div className="col-sm-12 col-12 col-md-12 col-lg-6">
                                    <Form.Item label={t('category')}>
                                        {getFieldDecorator('category', {
                                            rules: [{ required: true, message: t('please_input') }],
                                        })(
                                            <ItemCategoryFilter varient_data={this.state.categoryID} />
                                        )}
                                    </Form.Item>

                                </div>
                                <div className="col-sm-12 col-12 col-md-12 col-lg-6">
                                    <Form.Item label={t('Additional_price')}>
                                        {getFieldDecorator('additional_price', {
                                            rules: [{ required: true, message: t('please_input') }],
                                        })(
                                            <Input autoFocus onBlur={(val)=>{this.setState({supplement:val.target.value})}} placeholder={t('Additional_price')} />,
                                        )}
                                    </Form.Item>
                                </div>
                                <div className="col-sm-12 col-12 col-md-12 col-lg-6">
                                <Form.Item  label={t('Coating')}>
                                        {getFieldDecorator('revetement', {
                                            rules: [{ required: false, message: t('please_input') }],
                                        })(
                                            <Input autoFocus placeholder={t('Coating')}/>,
                                        )}
                                    </Form.Item>
                                </div>
                                <div className="col-sm-12 col-12 col-md-12 col-lg-6">
                                <Form.Item  label={t('Piping')}>
                                        {getFieldDecorator('passepoil', {
                                            rules: [{ required: false, message: t('please_input') }],
                                        })(
                                            <Input autoFocus placeholder={t('Piping')}/>,
                                        )}
                                    </Form.Item>
                                </div>
                                <div className="col-sm-12 col-12 col-md-12 col-lg-6">
                                <Form.Item  label={t('unit_price-no-component')}>
                                        {getFieldDecorator('unit_price', {
                                            rules: [{ required: false, message: t('please_input') }],
                                        })(
                                            <Input autoFocus placeholder={t('unit_price-no-component')}/>,
                                        )}
                                    </Form.Item>
                                </div>
                                <div className="col-sm-12 col-12 col-md-12 col-lg-6">
                                <Form.Item  label={t('group_price-no-component')}>
                                        {getFieldDecorator('group_price', {
                                            rules: [{ required: false, message: t('please_input') }],
                                        })(
                                            <Input autoFocus placeholder={t('group_price-no-component')}/>,
                                        )}
                                    </Form.Item>
                                </div>
                                <div className="col-sm-12 col-12 col-md-12 col-lg-6">
                                <Form.Item  label={t('quentity_group_price-no-component')}>
                                        {getFieldDecorator('group_quantity', {
                                            rules: [{ required: false, message: t('please_input') }],
                                        })(
                                            <Input autoFocus placeholder={t('quentity_group_price-no-component')}/>,
                                        )}
                                    </Form.Item>
                                </div>
                                <div className="col-sm-6 col-md-7 col-12 p-0">
                                    <div className="row mx-0 flex-align-center">
                                        <h4 className="mb-0 font-weight-bold text-uppercase mr-4"> {t('Components')}</h4>
                                        <Button
                                            onClick={() => { this.HandleComponentDrawer() }}
                                            type="primary"
                                            className="plus-btn text-uppercase main-btn-tag flex-align-center text-white font-weight-bold pl-2 pr-3">
                                            <img src={Images.plus_icon_white} /> {t('add_component')}</Button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                    {this?.state?.component?.map(val => {
                        return (
                            <div className="col-12 mt-5">
                                <div className="row added-cart-item mx-0 coupon-row p-0" style={{ border: '1px solid #e0e0e0', backgroundColor: '#FBF7FD' }}>
                                    <div className="col-sm-3 col-12 added-cart-img">
                                        <img className="img-fluid" src={val?.component?.images?.length ? val?.component?.images[0].image : ""} alt="item" />
                                    </div>
                                    <div className='col-12 col-sm-9 py-3'>
                                        <div className="row">
                                            <div style={{ borderRight: '1px solid #DCC4E5' }} className="col-sm-6 col-12 added-cart-price px-3">
                                                <h5>{val.component.sku}</h5>
                                                <ul className="added-pricing justify-content-start list-inline">
                                                    <li className='list-inline-item'>
                                                        <small style={{ color: '#646464' }}>{t(val.component.piece_type)}</small>
                                                    </li>
                                                    <li className='list-inline-item'>
                                                        <span className='d-inline-block' style={{ width: '27px', height: '11px', backgroundColor: val.component.colour_code }}></span>
                                                    </li>
                                                    <li className='list-inline-item'>
                                                        <small style={{ color: '#s' }}>{val.component.colour}</small>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-6 col-12 added-cart-list px-3">
                                                <div className="edit-btn-div-custom position-absolute">
                                                    <div onClick={() => { this.handleCardEdit(val) }} className="ant-btn bg-transparent border-0 p-0 shadow-none px-1"><span>Editer</span></div>
                                                    <div onClick={() => { this.handleCardDelete(val.id) }} className="ant-btn bg-transparent border-0 p-0 shadow-none px-1"><span>Éffacer</span></div>
                                                </div>
                                                <ul className="list-inline">
                                                                <li className='list-inline-item'>Prix du composant:</li>
                                                                <li className='list-inline-item'>€{val.unique_price}</li>
                                                            </ul>
                                                            <ul className="list-inline">
                                                                <li className='list-inline-item'>Prix Unitaire:</li>
                                                                <li className='list-inline-item'>€{val.unit_price}</li>
                                                            </ul>
                                                            <ul className="list-inline">
                                                                <li className='list-inline-item'>Prix groupage:</li>
                                                                <li className='list-inline-item'>€{val.group_price}</li>
                                                            </ul>
                                                            <ul className="list-inline">
                                                                <li className='list-inline-item'>Quantité pour recevoir le prix de groupe:</li>
                                                                <li className='list-inline-item'>{val.group_rate}</li>
                                                            </ul>
                                                            <ul className="list-inline">
                                                                <li className='list-inline-item'>Prix Supplementaire:</li>
                                                                <li className='list-inline-item'>€{this.state.supplement}</li>
                                                            </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div
                    className="steps-action steps-action-custom bg-white flex-align-center justify-content-end px-5">
                    <div>
                        <Button type="primary"
                            onClick={() => this.handleSubmit()}
                            className="font-weight-bold text-center text-white text-uppercase">
                            {this.props.match.params.method === methods.edit ? t('update_rate') : t('create_a_rate')}
                        </Button>
                    </div>
                </div>
                {params.method === methods.edit ?
                    confirmGroup ? <ConfirmPopup
                        onOk={() => history.push(reverse(routes.dashboard.profiles.tarifs.method, {
                            method: methods.view,
                            id: this.props.match.params.id
                        }))}
                        remove_left_btn={true}
                        width="47%"
                        image={Images.check_icon}
                        onCancel={() => this.confirmGroupVisible(false)}
                        okText={t('view_tarif')}
                        cancelText={false}
                        title={t('confirm_profile_update')}
                        description={t('group_updated')}
                        small_description={t('group_updated_main')}
                    /> : ""
                    :
                    confirmGroup ? <ConfirmPopup
                        onOk={() => history.push(reverse(routes.dashboard.profiles.tarifs.method, {
                            method: methods.view,
                            id: this?.state?.groupid
                        }))}
                        width="47%"
                        image={Images.check_icon}
                        onCancel={() => this.props.history.go(-1)}
                        okText={t('view_tarif')}
                        cancelText={"Ok"}
                        title={t('profile_create_confirm')}
                        description={t('created_group_new')}
                        small_description={t('created_group_profile_main')}
                    /> : ""
                }
                {this.state.visibleTarifsDrawer &&
                    <CreateTarifs allComponent={this.state.component} visible={this.state.visibleTarifsDrawer} onClose={() => this.visibleTarifsCreateDrawer(false)} data={this.state.cardData} drawer_component_data={this.state.drawer_component_data} component={(val) => this.componentStateUpdate(val)} varientID={this.state.varientID} sku={this.state.sku}/>
                }
            </React.Fragment>
        );
    }
}


export const TarifsGroupProfileForm = Form.create({
    name: "group_tarifaire",
        mapPropsToFields(props) {
        if (props.group) {
            const group = props.group;
            return {
                name: Form.createFormField({
                    value: group.name,
                }),
                tariff_group: group.tariff_group ? Form.createFormField({
                    value: {
                        key: group.tariff_group.id,
                        label: group.tariff_group.name
                    },
                }) : "",
                category: group.category ? Form.createFormField({
                    value: {
                        key: group.category.id,
                        label: group.category.name
                    },
                }) : "",
                family: group.family ? Form.createFormField({
                    value: {
                        key: group.family.id,
                        label: group.family.name
                    },
                }) : "",
                variant_sku: group.category ? Form.createFormField({
                    value: group.variant_sku
                }) : "",
                additional_price: Form.createFormField({
                    value: group.additional_price,
                }),
                revetement: Form.createFormField({
                    value: group.revetement,
                }),
                passepoil: Form.createFormField({
                    value: group.passepoil,
                }),
                unit_price: Form.createFormField({
                    value: group.unit_price,
                }),
                group_price: Form.createFormField({
                    value: group.group_price,
                }),
                group_quantity: Form.createFormField({
                    value: group.group_quantity,
                }),
            }
        }

    }
})(withTranslation('common')(withRouter(TarifsGroupProfileFormComponent)));

