import React, { Component } from 'react';
import { Image as Images } from "../../Images";
import { Button, Icon, Spin } from "antd";
import AddItemsListDrawer from "../../drawers/work-order/add-items/AddItemsListDrawer";
import { locationItemGet, locationItemRemove, locationItemUpdate } from "../../../controller/API/salesOperationAPI";
import { withTranslation } from "react-i18next";
import SingleItemListCard from "./SingleItemListCard";
import SingleComponentListCard from './SingleComponentListCard';
import AddComponentItemDrawer from '../../warehouse/component/drawers/AddComponentItemDrawer';
import AddComponentListDrawer from '../../drawers/work-order/add-items/AddComponentListDrawer';
import { ConsoleSqlOutlined } from '@ant-design/icons';

class ComponentList extends Component {
    state = {
        itemListShow: false,
        radioValue: 1,
        items: [],
        params: { location_id: this.props.location.id },
        loading: true,
        cardid: null,
        dataId: null,
        selectIndex: null
    };

    itemListVisible = (visible, item, dataId) => {
        const { params } = this.state;
        this.setState({ cardid: item })
        this.setState({
            itemListShow: visible,
            dataId: dataId
        });
        if (!visible) {
            this.fetch(params);
            this.props.fetch()
        }
    };

    fetch = (params = {}) => {
        locationItemGet(params)
            .then(response => {
                if (response.count > 0) {
                    this.props.buttonFaded();
                }
                this.setState({ items: response.data, loading: false })
            })
    };


    deleteItem = (id) => {
        locationItemRemove(id)
            .then(() => this.fetch(this.state.params))
    };

    componentDidMount() {
        const { params } = this.state;
        this.fetch(params);
    }


    updateItem = (id, data) => {
        locationItemUpdate(id, data)
            .then(() => this.fetch(this.state.params))
    };

    getItemsList() {
        const { items, params } = this.state;
        return items.map((data, index) =>
           {
            return <SingleComponentListCard onOpen={(val, id) => this.itemListVisible(val, id, data.item.id)} key={`item_card_${index}`} data={data} update={(id, data) => this.updateItem(id, data)}
                fetch={() => this.fetch(params)}
                remove={(id) => this.deleteItem(id)} />}
        )
    }

    render() {
        
        const { itemListShow, items, loading } = this.state;
        const { location, t } = this.props;
        if (loading) {
            return <div className={'mt-5 p-0 text-center'}><Spin /></div>
        }
        return (
            <React.Fragment>
                {items.length &&
                    <React.Fragment>
                        {this.getItemsList()}
                    </React.Fragment>

                }
                {itemListShow &&
                    <AddComponentListDrawer dataId={this.state.dataId} cardId={this.state.cardid} totalItems={items.length} id={this.state.cardid} current_location={location} visible={itemListShow}
                        onClose={() => this.itemListVisible(false)}/>}
            </React.Fragment>
        );
    }
}

export default (withTranslation('common')(ComponentList));
