import React, {Component} from 'react';
import {Button, Checkbox} from "antd";
import {
    locationGroupItemGet,
    locationItemRemove,
    locationItemUpdate
} from "../../../../controller/API/salesOperationAPI";
import {withTranslation} from "react-i18next";
import SingleItembygroup from '../SingleItembygroup';
import {connect} from "react-redux";
import {mapWarehouseDispatchToProps, mapWarehouseStateToProps} from "../../../../redux/Actions/WarehouseAction";
import {isAccessible} from "../../../../utils";
import { GroupPopoverContent } from './GroupPopoverContent';
import { AddGroupCommentPopup } from '../modals/AddGroupCommentPopup';

const moment = require('moment');


class GroupItemCard extends Component {
    state = {
        itemListShow: false,
        items: [],
        loading: true,
        commentPopup: false,
        commentData: null,

        allChecked: false,
        checkboxArray: [],
        checkedList: [],
        indeterminate: false,
        checkAll: false,
        selectedItem: {}
    };

    fetch = (params = {}) => {
        locationGroupItemGet(params)
            .then(response => {
                this.setState({items: response, loading: false})
            })
    };

    deleteItem = (id) => {
        locationItemRemove(id)
            .then(() => this.fetch(this.state.params))
    };

    visibleCommentPopup = (visible, data = null) => {
        this.setState({commentPopup: visible, commentData: data})
    };

    componentDidMount() {
        const {data} = this.props;
        const checkboxArray = [];
        data.items.map((item) => checkboxArray.push(item.id));
        this.setState({checkboxArray})
    }

    updateItem(id, data) {
        locationItemUpdate(id, data)
            .then(() =>
                this.fetch({location_id: this.props.location.id}))
    }

    getItemsList(items) {
        const {params} = this.state;
        const warehouseMapping = {}
        for (let item of items) {
            if (!(item.warehouse_item.warehouse in warehouseMapping)) {
                warehouseMapping[item.warehouse_item.warehouse] = []
            }
            warehouseMapping[item.warehouse_item.warehouse].push(item)
        }
        let nodes = []
        for (const warehouse in warehouseMapping) {
            if (isAccessible(['admin'])) {
                nodes.push(
                    <p className={`mt-1 text-left font-weight-bold`} style={{color: '#448de5', marginLeft: '100px'}}
                       key={`warehouse_${warehouse}`}>
                        {this.props.warehouse_id_mapping[warehouse].name} <br/>
                        <span
                            className={'font-weight-normal'}>{this.props.warehouse_id_mapping[warehouse].address}</span><br/>
                        <span
                            className={'font-weight-normal'}>{this.props.warehouse_id_mapping[warehouse].business_hours}</span><br/>
                        <span className={'font-weight-normal'}>{this.props.warehouse_id_mapping[warehouse].phone}</span>
                    </p>
                )
            }

            warehouseMapping[warehouse].map((data, index) => nodes.push(
                <SingleItembygroup key={`item_card_${index}`} data={data} update={(id, data) => this.updateItem(id, data)}
                                   fetch={() => this.fetch(params)}
                                   delivery_setting={true}
                                   workorder={this.props.workorder}
                                   remove={(id) => this.deleteItem(id)}
                                   itemCheckbox={this.props.groupCheckbox}
                                   />
            ))
        }
        return nodes
    }

    onChangeCheck = checkedList => {
        const {checkboxArray} = this.state;
        this.setState({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < checkboxArray.length,
            checkAll: checkedList.length === checkboxArray.length,
        });
    };

    onCheckAllChange = e => {
        const {checkboxArray} = this.state;
        this.setState({
            checkedList: e.target.checked ? checkboxArray : [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
    };

    resetCheckbox = ()=>{
        this.setState({checkedList: [], checkAll:false, indeterminate:false})
        this.props.fetch()
    }

    render() {
        const {items, loading, commentData, commentPopup, params} = this.state;
        const {t, groupCheckbox,data, index, workorder} = this.props;
        const {checkedList, checkAll, indeterminate} = this.state;

        return (
                <React.Fragment key={`group_item_${index}`}>
                    <div className="group-date pb-0">
                        <div className='d-flex align-items-center group-action-div '>
                            <GroupPopoverContent
                                workorder={workorder}
                                title={'Mettre à jour la date de livraison'}
                                data={checkedList}
                                fetch={() => this.resetCheckbox()}
                            />
                            <div style={{marginRight: '10px', marginLeft: '10px'}}>
                                {groupCheckbox && 
                                    <Checkbox
                                        indeterminate={indeterminate}
                                        onChange={this.onCheckAllChange}
                                        checked={checkAll}>
                                    </Checkbox>}
                            </div>
                            <h5 style={{marginLeft: '10px'}}><span
                                className="text-uppercase">{data.name}</span> : <span>{moment(data.delivery_date).format('DD/MM/YYYY')} </span><span> {data.comment}</span>
                            </h5>
                            <Button className="add-data-btn ml-2" onClick={() => this.visibleCommentPopup(true, data)}>
                                {data.comment ? t('edit') : t('add_comment')}
                            </Button>
                        </div>
                    </div>

                    <Checkbox.Group value={checkedList} onChange={this.onChangeCheck}>
                        {this.getItemsList(data.items)}
                    </Checkbox.Group>
                    <div>
                        {commentPopup &&
                            <AddGroupCommentPopup visible={commentPopup} close={() => this.visibleCommentPopup(false)}
                                data={commentData} fetch={() => this.props.fetch()}/>
                        }
                    </div>
                </React.Fragment>
        );
    }
}

export default (withTranslation('common')(connect(mapWarehouseStateToProps, mapWarehouseDispatchToProps)(GroupItemCard)));