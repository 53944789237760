import React, { Component } from 'react';
import { Button, Form, InputNumber } from "antd";
import { withTranslation } from "react-i18next";
import { Collapse } from 'antd';
import ComponentItemCard from './ComponentItemCard';
import { Image as Images } from "../../Images";
import { quantity_update } from '../../../controller/API/salesOperationAPI';

class SingleComponentListCard extends Component {

    onInputNumberChange = (value) => {
        if (value) {
            const { data } = this.props
            quantity_update({ 'work_order_item_id': data.id, 'quantity': value })
            // this.props.update(data.id,{'quantity':value} )
        }
    }

    myFun = (total, current) => {
        return total + Number(current.price);
    }

    render() {
        const { Panel } = Collapse;
        const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
        const { t, data, delivery_setting } = this.props;
        const { getFieldDecorator } = this.props.form;


        return (

            <>
                <div className="row card-main-common">
                    <div className="col-12">
                        <div className="row added-cart-item mb-0 mx-0">
                            <div className="col-sm-3 col-12 added-cart-img">
                                <img className="img-fluid"
                                    src={data.item.variant_images.length > 0 ? data.item.variant_images[0].image : ""}
                                    alt="item" />
                            </div>
                            <div className="col-sm-5 col-12 added-cart-price">
                                <h5>{data.item.product.name} <span>{data.item.sku}</span></h5>
                                <p className="added-color">
                                    <small>{data.item.product.category.name}</small>
                                    <i style={{ backgroundColor: `${data.item.colour_code}` }}
                                        className="fa fa-circle" />{data.item.colour}</p>
                                <div className="added-pricing">
                                    <ul className="list-inline mb-0 price-qty-ul">
                                        <li>${parseFloat(data.single_item_price).toFixed(2)}</li>
                                        <li>QTY:{data.quantity}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-4 added-cart-type">
                                <div className="row h-100 mx-0 align-items-center justify-content-center">
                                    <Button style={{ fontSize: '10px', lineHeight: '14px;', borderRadius: '25px' }}
                                        type="primary"
                                        onClick={() => this.props.onOpen(true, this.props?.data?.id, this.props?.data?.item?.id)}>
                                        {t('add_a_component')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {data.workorder_component.length ?
                            <div style={{ backgroundColor: '#ebecef', paddingBottom: '5px' }} className="w-100">
                                {data.workorder_component?.map((item) => {
                                    return <ComponentItemCard item={item} data={data} fetch={() => this.props.fetch()} />
                                })}
                            </div>
                            : ""}
                    </div>
                    <div className="col-12">
                        <div className="row mx-0 card-details-footer">
                            <div className="col-12 sm-12 col-md-6">
                                <h6 className="mb-0">Prix article + Prix composant (si présent)</h6>
                            </div>
                            <div className="col-12 sm-12 col-md-3 pl-0">
                                <ul className="list-inline mb-0 d-flex align-items-center">
                                    <li className="list-inline-item">QTY:</li>
                                    <li className="list-inline-item">
                                        {data.quantity}
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 sm-12 col-md-3 border-left-1">
                                <ul className="list-inline mb-0 text-right">
                                    <li className="list-inline-item pr-4">Prix total:</li>
                                    <li className="list-inline-item">{parseFloat(data.total_price).toFixed(2)}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>);
    }
}

export default Form.create()(withTranslation('common')(SingleComponentListCard));
