import React, {Component} from 'react';
import {SelectAddress} from "./SelectAddress";
import {addressGet, distributorProfileGet, distributorProfileGetOne} from "../../../controller/API/profileApi";
import {withRouter} from "react-router-dom";
import {Spin} from "antd";
import { depot_list_get } from '../../../controller/API/salesOperationAPI';
import { isAccessible } from '../../../utils';

class SelectItemLocation extends Component {
    state = {
        locationSelected: false,
        all_locations: [],
        loading: true,
        depot_list:[],
        distributorAddress: [],
    };

    componentDidMount() {
        if (this.props.work_order){
            this.fetch({client_id: this.props.work_order.client.id, "page_size": "all"})
        }else{
            this.fetch({"page_size": "all"})
        }
        this.getDepotList()
        this.fetchDistributor();
    }

    fetchDistributor = () => {
        if(isAccessible(["admin"]) === true){
            distributorProfileGetOne(this.props.work_order.client.id)
            .then(response => {
                const defaultAddress = response.address?.find((address)=> address.default === true)
                this.setState({distributorAddress: defaultAddress !== undefined ? [defaultAddress.id] : [], loading: false})
            })
        }else{
            distributorProfileGet()
            .then(response => {
                if(response.data && response.data.length > 0){
                    const defaultAddress = response.data[0].address.find((address)=> address.default === true)
                    this.setState({distributorAddress: defaultAddress !== undefined ? [defaultAddress.id] : [], loading: false})
                }
            })
        }
        };


    fetch = (params = {}) => {
        addressGet(params)
            .then(response => {
                this.setState({all_locations: response})
            })
    };
    getDepotList=()=>{
        depot_list_get()
        .then(response=>this.setState({depot_list:response?.results}))
    }

    render() {
        const {all_locations, distributorAddress} = this.state;
        if (this.state.loading) {
            return <div className={'mt-5 p-0 text-center'}><Spin/></div>
        }
        return (
            <React.Fragment>
                <SelectAddress onPrev={this.props.prev}
                               workorder={this.props.work_order}
                               all_locations={all_locations}
                               defaultAddress={distributorAddress}
                               setWorkorder={(work_order) => this.props.next(work_order)}
                               onNext={() => this.props.next(this.props.work_order)}
                               fetch={this.fetch} depot_list={this.state.depot_list}/>
            </React.Fragment>
        );
    }
}

export default withRouter(SelectItemLocation);
