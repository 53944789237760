import React, { Component } from 'react';
import { Button, Form, Input, message } from 'antd';
import { createTarifFaire, createTarifGroup, groupPost, groupUpdate, tarifFaireUpdate } from "../../../controller/API/profileApi";
import { Image as Images } from "../../Images";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { showErrors } from "../../../controller/utils";
import { methods } from "../../../controller/Global";
import ConfirmPopup from "../../modal/ConfirmPopup";
import { history } from "../../../controller/history";
import { reverse } from "named-urls";
import { routes } from "../../../controller/routes";


class TarifaireGroupProfileFormComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmGroup: false,
            groupid:null
        };
    }

    handleSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const { match } = this.props;
                if (match.params.method === methods.create) {
                    createTarifFaire(values).then((data) => {
                        this.confirmGroupVisible(true,data?.id);
                    }).catch((error) => message.error(error))
                }
                else {
                    tarifFaireUpdate(this.props.match.params.id, values).then((value) => {
                        this.confirmGroupVisible(true,null);
                    }).catch((error) => message.error(error))
                }


            }
        })
    };

    confirmGroupVisible = (visible,id) => {
        this.setState({ confirmGroup: visible })
        this.setState({groupid:id})
    };

    render() {
        const { t, group } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { params } = this.props.match;
        const { confirmGroup } = this.state;
        return (
            <React.Fragment>
                <div className="row mx-0 steps-main-row">
                    <div className="col-12">
                        <h6 className="all-heading-div text-uppercase font-weight-bold mb-4 pb-2">{params.method === methods.edit ? t('group_info') : t('groupe_tarifaire_iNFORMATIONS')}</h6>
                    </div>
                    <div className="col-12">
                        <Form className="main-form">
                            <div className="row">
                                <div className="col-sm-12 col-12 col-md-12 col-lg-6">
                                    <div className="row">
                                        <div className="col-12">
                                            <Form.Item label={t('nom')}>
                                                {getFieldDecorator('name', {
                                                    rules: [{ required: true, message: t('please_input') }],
                                                })(
                                                    <Input autoFocus placeholder={params.method === methods.edit ? `${this.props.informationData.name ? this.props.informationData.name : t('enter :')}` : t('nom')} />,
                                                )}
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <div
                    className="steps-action steps-action-custom bg-white flex-align-center justify-content-end px-5">
                    <div>
                        <Button type="primary"
                            onClick={this.handleSubmit}
                            className="font-weight-bold text-center text-white text-uppercase">
                            {this.props.match.params.method === methods.edit ? t('update_profile_btn') : t('crete_profill')}
                        </Button>
                    </div>
                </div>
                {params.method === methods.edit ?
                    confirmGroup ? <ConfirmPopup
                        onOk={() => history.push(reverse(routes.dashboard.profiles.tarifaire.method, {
                            method: methods.view,
                            id: this.props.match.params.id
                        }))}
                        remove_left_btn={true}
                        width="47%"
                        image={Images.check_icon}
                        onCancel={() => this.confirmGroupVisible(false)}
                        okText={t('view_tarif_group')}
                        cancelText={false}
                        title={t('confirm_profile_update')}
                        description={t('group_updated')}
                        small_description={t('group_updated_main')}
                    /> : ""
                    :
                    confirmGroup ? <ConfirmPopup
                        onOk={() => history.push(reverse(routes.dashboard.profiles.tarifaire.method, {
                            method: methods.view,
                            id: this.state.groupid
                        }))}
                        width="47%"
                        image={Images.check_icon}
                        onCancel={() => this.props.history.go(-1)}
                        okText={t("view_tarif_group")}
                        cancelText={"Ok"}
                        title={t('profile_create_confirm')}
                        description={t('created_group_new')}
                        small_description={t('created_group_profile_main')}
                    /> : ""
                }
            </React.Fragment>
        );
    }
}


export const TarifaireGroupProfileForm = Form.create({
    name: "group_tarifaire",
    mapPropsToFields(props) {
        if (props.informationData) {
            return {
                name: Form.createFormField({
                    value: props.informationData.name
                }),
            }
        }

    }
})(withTranslation('common')(withRouter(TarifaireGroupProfileFormComponent)));