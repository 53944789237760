import React, { Component } from "react";
import { Button, Form, Icon, Input, InputNumber, message } from "antd";
import ItemDetailsModal from "../../../modal/work-order/ItemDetailsModal";
import { withTranslation } from "react-i18next";
import { Image } from "../../../Images";

const moment = require("moment");

class SingleItemCardFormComponent extends Component {

    state = {
        itemDetailsShow: false,
        selected: 0,
        price: null,
        quantity: this.props.product.variant[0].units_per_set,
        buttonLoading: false
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({ buttonLoading: true });
        const { selected, price } = this.state;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.submit(selected);
                this.setState({ buttonLoading: false });

            }
        });

    };

    setPrice = (quantity) => {
        const { selected } = this.state;
        this.setState({ price: this.getPrice(selected, quantity), quantity });
    };

    getPrice = (selected, quantity = null) => {
        const { product } = this.props;
        if (!quantity) {
            quantity = product.variant[selected].units_per_set;
        }
        return quantity * product.variant[selected].price;
    };

    componentDidMount() {
        this.setState({ price: this.getPrice(this.state.selected) });
    }

    changeSelected = (selected) => {
        this.setState({
            selected, quantity: this.props.product.variant[selected].units_per_set,
            price: this.getPrice(selected)
        });
    };

    render() {
        const { product, t, container } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { selected, itemDetailsShow, price, buttonLoading } = this.state;
        return (
            <React.Fragment>
                <div className="col-sm-12 col-lg-6 col-md-12 col-12 pr-2 padding-responsive-right">
                    <div className="row mx-0 item_single-card">
                        <div className="item-img-div flex-align-center-center">
                            <Button
                                className="bg-transparent shadow-none p-0 border-0 h-auto">
                                <img
                                    src={product.variant[selected].variant_images.length > 0 ? product.variant[selected].variant_images[0].image : ""}
                                    className="img-fluid" alt="chair-img" />
                            </Button>
                        </div>
                        <div className="item-data-div position-relative">
                            <h5 className="font-weight-bold">{product.name}</h5>
                            <ul className="list-inline mb-0 w-100">
                                <li className="list-inline-item m-0">{t("product_ref")} :</li>
                                <li
                                    className="list-inline-item m-0">{product.variant[selected].sku}</li>
                            </ul>
                            <ul className="list-inline mb-0 w-100">
                                <li className="list-inline-item ">{t("category")} :</li>
                                <li className="list-inline-item  dark-gray">{product.category.name}</li>
                            </ul>

                            <ul className="list-inline mb-0 w-100">
                                <li className="list-inline-item ">{t("color")} :</li>
                                <li className="list-inline-item ">
                                    {product.variant.map((variant, index) =>
                                        <span key={`color_code_${index}`} style={{ backgroundColor: variant.colour_code }}
                                            onClick={() => this.changeSelected(index)}
                                            className={`color-card d-inline-block mr-1 ${selected === index ? "active" : null}`} />
                                    )}
                                </li>
                            </ul>
                            <div
                                className="card-footer-div flex-align-center justify-content-end position-absolute">
                                <Button loading={buttonLoading} type="primary" onClick={this.handleSubmit}>
                                    <Icon type="plus" />
                                    {t("add")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export const SingleItemCardComponent = Form.create()(withTranslation("common")(SingleItemCardFormComponent));
