import React, { Component } from 'react';
import { Button, Empty, Form, message, Pagination, Select, Spin, Table, Tabs } from "antd";
import { withTranslation } from "react-i18next";
import TarifaireGroupSidebar from './TarifaireGroupSidebar';
import { CreateTarifarePDF } from "../../drawers/tarifare/TarifaireGenerateDrawer";
import TarifsGroupSidebar from './TarifsGroupSidebar';
import { getTariff, getTariffOne } from '../../../controller/API/profileApi';

const { TabPane } = Tabs;



class TarifsGroupView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            group: null,
            Tabkey: "1",
            data: {},
            loading: false,
        }
    }

    handleTarifOpen = (visible) => {
        this.setState({
            open: visible
        })
    }

    componentDidMount() {
        this.fetch(this.props.match.params.id);
    }
    fetch = (id) => {
        this.setState({ loading: true });
        getTariffOne(id).then((value) => {
            this.setState({ data: value, loading: false });
        }).catch((err) => { message.error(err) });
    }



    render() {
        const { group, sidebarButton, loading } = this.state;
        const { t } = this.props;
        if (loading) {
            return <div className={'mt-5 p-0 text-center d-flex align-items-center justify-content-center w-100'}>
                <Spin />
            </div>
        }
        return (<React.Fragment>
            <TarifsGroupSidebar group={group} data={this.state.data} {...this.props}
                fetch={() => this.fetch(this.props.match.params.id)} />
            <div className="profile-summary-details-row">
                <div className="col-12 p-0">
                    <Tabs type="card" defaultActiveKey={"1"} activeKey={this.state.Tabkey}
                        onChange={this.handleonChange}>
                        <TabPane tab={t('summary')} key="1">
                            <div className="row summary-info-row mx-0">
                                <div className="col-12 px-0">
                                    <h6 className="text-uppercase font-weight-bold mb-4">{t('tariff_information')} </h6>
                                </div>
                                <div className="col-12 px-0">
                                    <div className="card border-0 position-relative mb-4">
                                        <div className="card-body">
                                            <div className="row mx-0">
                                                <div className="col-md-6 col-sm-6 col-12 col-lg-4">
                                                    <ul className="list-inline">
                                                        <li className="list-inline-item label-text-li">{t('group_name')} :
                                                        </li>
                                                        <li className="list-inline-item label-name-li">{this.state.data?.tariff_group?.name}</li>
                                                    </ul>
                                                    <ul className="list-inline">
                                                        <li className="list-inline-item label-text-li">{t('nom')} :
                                                        </li>
                                                        <li className="list-inline-item label-name-li">{this.state.data.name}</li>
                                                    </ul>
                                                    <ul className="list-inline">
                                                        <li className="list-inline-item label-text-li">{t('family')} :
                                                        </li>
                                                        <li className="list-inline-item label-name-li">{this.state.data?.family?.name}</li>
                                                    </ul>
                                                    <ul className="list-inline">
                                                        <li className="list-inline-item label-text-li">{t('category')} :
                                                        </li>
                                                        <li className="list-inline-item label-name-li">{this.state.data?.category?.name}</li>
                                                    </ul>
                                                    <ul className="list-inline">
                                                        <li className="list-inline-item label-text-li">{t('reference')} :
                                                        </li>
                                                        <li className="list-inline-item label-name-li">{this.state.data?.variant_sku}</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6 col-sm-6 col-12 col-lg-4">
                                                    <ul className="list-inline">
                                                        <li className="list-inline-item label-text-li">{t('Additional_price')} :
                                                        </li>
                                                        <li className="list-inline-item label-name-li">{this.state.data.additional_price}</li>
                                                    </ul>
                                                    <ul className="list-inline">
                                                        <li className="list-inline-item label-text-li">{t('Coating')} :
                                                        </li>
                                                        <li className="list-inline-item label-name-li">{this.state.data?.revetement}</li>
                                                    </ul>
                                                    <ul className="list-inline">
                                                        <li className="list-inline-item label-text-li">{t('Piping')} :
                                                        </li>
                                                        <li className="list-inline-item label-name-li">{this.state.data?.passepoil}</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6 col-sm-6 col-12 col-lg-4">
                                                    <ul className="list-inline">
                                                        <li className="list-inline-item label-text-li">{t('unit_price-no-component')} :
                                                        </li>
                                                        <li className="list-inline-item label-name-li">{this.state.data.unit_price}</li>
                                                    </ul>
                                                    <ul className="list-inline">
                                                        <li className="list-inline-item label-text-li">{t('group_price-no-component')} :
                                                        </li>
                                                        <li className="list-inline-item label-name-li">{this.state.data?.group_price}</li>
                                                    </ul>
                                                    <ul className="list-inline">
                                                        <li className="list-inline-item label-text-li">{t('quentity_group_price-no-component')} :
                                                        </li>
                                                        <li className="list-inline-item label-name-li">{this.state.data?.group_quantity}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 className="mb-0 font-weight-bold text-uppercase mr-4"> {t('tariff_information')}</h4>
                                {this?.state?.data?.component?.map(val => {
                                    return (
                                        <div className="col-12 mt-5">
                                            <div className="row added-cart-item mx-0 coupon-row p-0" style={{ border: '1px solid #e0e0e0', backgroundColor: '#FBF7FD' }}>
                                                <div className="col-sm-3 col-12 added-cart-img">
                                                    <img className="img-fluid" src={val?.component?.images?.length ? val?.component?.images[0].image : ""} alt="item" />
                                                </div>
                                                <div className='col-12 col-sm-9 py-3'>
                                                    <div className="row">
                                                        <div style={{ borderRight: '1px solid #DCC4E5' }} className="col-sm-6 col-12 added-cart-price px-3">
                                                            <h5>{val.component.sku}</h5>
                                                            <ul className="added-pricing justify-content-start list-inline">
                                                                <li className='list-inline-item'>
                                                                    <small style={{ color: '#646464' }}>{t(val.component.piece_type)}</small>
                                                                </li>
                                                                <li className='list-inline-item'>
                                                                    <span className='d-inline-block' style={{ width: '27px', height: '11px', backgroundColor: val.component.colour_code }}></span>
                                                                </li>
                                                                <li className='list-inline-item'>
                                                                    <small style={{ color: '#s' }}>{val.component.colour}</small>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-sm-6 col-12 added-cart-list px-3">
                                                            <ul className="list-inline">
                                                                <li className='list-inline-item'>Prix du composant:</li>
                                                                <li className='list-inline-item'>€{val.unique_price}</li>
                                                            </ul>
                                                            <ul className="list-inline">
                                                                <li className='list-inline-item'>Prix Unitaire:</li>
                                                                <li className='list-inline-item'>€{val.unit_price}</li>
                                                            </ul>
                                                            <ul className="list-inline">
                                                                <li className='list-inline-item'>Prix groupage:</li>
                                                                <li className='list-inline-item'>€{val.group_price}</li>
                                                            </ul>
                                                            <ul className="list-inline">
                                                                <li className='list-inline-item'>Quantité pour recevoir le prix de groupe:</li>
                                                                <li className='list-inline-item'>{val.group_rate}</li>
                                                            </ul>
                                                            <ul className="list-inline">
                                                                <li className='list-inline-item'>Prix Supplementaire:</li>
                                                                <li className='list-inline-item'>€{this.state.data.additional_price}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </TabPane>
                    </Tabs>
                    <h5 className="header-tab-heading position-fixed text-uppercase font-weight-bold mb-0">{t('France_tariff')}</h5>
                </div>
            </div>
        </React.Fragment>);
    }
}

export default (withTranslation('common')(TarifsGroupView));

