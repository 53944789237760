import {Component} from "react";
import {connect} from "react-redux";
import {mapWarehouseDispatchToProps, mapWarehouseStateToProps} from "../../../redux/Actions/WarehouseAction";
import {withTranslation} from "react-i18next";
import { WarningOutlined } from '@ant-design/icons';

class WarehouseInventoryInfo extends Component {
    render() {
        let {editable, warehouse_id_mapping, t, onEdit, onDelete, inventory} = this.props;
        return (
            <div className={'d-flex w-100 address-add-row px-3 mt-0'} style={{borderColor: '#CADEF9'}}>
                <div className={'flex-grow-1'} style={{ color: '#071930', fontWeight: '500', width: '18%'}}>{warehouse_id_mapping[inventory.warehouse].name}</div>
                <div className={'flex-grow-1 px-3'} style={{ color: '#071930', fontWeight: '500', width: '18%'}}>{inventory.in_stock} ( <WarningOutlined /> at {inventory.in_stock_alert_threshold})</div>
                <div className={'flex-grow-1 px-3'} style={{ color: '#071930', fontWeight: '500', width: '18%'}}>{inventory.in_queued}</div>
                <div className={'flex-grow-1 px-3'} style={{ color: '#071930', fontWeight: '500', width: '18%'}}>{inventory.in_available_stock} ( <WarningOutlined /> at {inventory.available_alert_threshold})</div>
                <div className={'flex-grow-1 px-3'} style={{ color: '#071930', fontWeight: '500', width: '18%'}}>{inventory.in_transit}</div>

                {
                    editable === true &&
                    <div className={'mx-1'} style={{width: '10%'}}>
                        <small className={'mx-1 text-capitalize'} onClick={(e) => onEdit(e)}
                               style={{cursor: 'pointer', verticalAlign: 'top', color: '#071930', textDecoration: 'underline'}}>EDITER</small>
                        <small className={'text-capitalize'} onClick={(e) => onDelete(e)}
                               style={{cursor: 'pointer', verticalAlign: 'top', color: '#071930', textDecoration: 'underline'}}>ÉFFACER</small>
                    </div>
                }
            </div>
        )
    }

}

export default withTranslation('common')(connect(mapWarehouseStateToProps, mapWarehouseDispatchToProps)(WarehouseInventoryInfo))