import React, { Component } from 'react';
import { Button, Collapse, Form, InputNumber, message, Modal } from "antd";
import { withTranslation } from "react-i18next";
import { discount_coupon_component, distributorValidateItemDiscount, quantity_update } from "../../../../controller/API/salesOperationAPI";
import ConfirmationPopup from "./ConfirmationPopop";
import ComponentItemCard from '../ComponentItemCard';
import DiscountCard from './DiscountCard';
import ConfirmPopup from '../../../modal/ConfirmPopup';
import { Image } from '../../../Images';
import VerifierTarifPopup from './VerifierTarifPopup';
const { Panel } = Collapse;

class SingleItemListCardDiscount extends Component {
    state = {
        confirmationVisible: false,
        discount: {},
        visible: false,
        tarifNotapplyPopup: false
    }
    onInputNumberChange = (value) => {
        if (value) {
            const { data } = this.props
            quantity_update({ work_order_item_id: data.id, quantity: value }).then(() =>
                this.props.fetch({ location_id: this.props.location.id }))

            // this.props.update(data.id,{'quantity':value} )
        }
    }
    validateCoupon = () => {
        const { data } = this.props;
        distributorValidateItemDiscount({ item: data.id })
            .then(response => {
                const discount = response.data.discount
                this.setState({ discount })
                this.handleVisibleConfirmation(true)
            })
            .catch(error => {
                message.info(error.response.data.message)
            })
    }
    validateComponentCoupon = () => {
        const { data } = this.props
        discount_coupon_component({ workorder_item: data.id }).then((response) => {
            this.setState({ visible: true })
        }).catch(error => {
            this.setState({ tarifNotapplyPopup: true })
        })
    }
    handleVisibleConfirmation = (confirmationVisible) => {
        this.setState({ confirmationVisible })
    }
    updateDiscountData = () => {
        this.props.fetch()
    }

    removeCoupon = () => {

    }

    render() {
        const { data } = this.props;
        const { confirmationVisible, discount } = this.state
        const { getFieldDecorator } = this.props.form;
        return (
            <>
                <div className="row card-main-common">
                    <div className="col-12">
                        <div className="row added-cart-item mx-0 coupon-row mb-0">
                            <div className="col-sm-3 col-12 added-cart-img">
                                <img className="img-fluid"
                                    src={data.warehouse_item.variant.variant_images.length > 0 ? data.warehouse_item.variant.variant_images[0].image : ""}
                                    alt="item" />
                            </div>
                            <div className="col-sm-5 col-12 added-cart-price">
                                <h5>{data.warehouse_item.variant.product.name} <span>{data.warehouse_item.variant.sku}</span></h5>
                                <p className="added-color">
                                    <small>{data.warehouse_item.variant.product.category.name}</small>
                                    <i style={{ backgroundColor: `${data.warehouse_item.variant.colour_code}` }}
                                        className="fa fa-circle" />{data.warehouse_item.variant.colour}</p>
                                <div className="added-pricing">
                                    <ul className="list-inline mb-0 price-qty-ul">
                                        <li>€{parseFloat(data.single_item_price).toFixed(2)}</li>
                                        <li>QTY: {data.quantity}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-4 col-12 added-cart-type">
                                {data.client_discount?.id ?
                                    data.client_discount.discount_type === "REDUCTION_AMOUNT" ?
                                        <h6> {`${data.client_discount.amount} montant ${data.client_discount.quantity} articles`}</h6>
                                        :
                                        <h6> {`–${data.client_discount.percentage}% pour ${data.client_discount.quantity} articles`}</h6>
                                    : ""
                                }
                                {data.client_discount?.id || data?.tariff_discount ?
                                    data?.tariff_discount ?
                                        <Button disabled
                                            className={"add-coupon-btn ml-2 discount-applied"}
                                            onClick={this.removeCoupon}>SUPPRIMER Tarif</Button>
                                        :
                                        <Button disabled
                                            className={"add-coupon-btn ml-2 discount-applied"}
                                            onClick={this.removeCoupon}>SUPPRIMER LE COUPON</Button>
                                    :

                                    <>
                                        <Button className={"add-coupon-btn ml-2 "}
                                            onClick={this.validateCoupon}>APPLIQUER COUPON</Button>
                                        <Button className={"add-coupon-btn add-btn  ml-2 mt-2 "}
                                            onClick={() => this.validateComponentCoupon()}>VÉRIFIER LES TARIFS</Button> 
                                    </>


                                }
                            </div>
                            <ConfirmationPopup closePopup={() => this.handleVisibleConfirmation(false)} discount={discount}
                                data={data} visible={confirmationVisible}
                                updateDiscountData={this.updateDiscountData} />
                        </div>
                        {data.workorder_component.length ?
                            <div style={{ backgroundColor: '#ebecef', paddingBottom: '5px' }} className="w-100">
                                {data.workorder_component?.map((item) => {
                                    return <ComponentItemCard item={item} data={data} fetch={() => this.props.fetch()} />
                                })}
                            </div>
                            : ""}
                    </div>
                    <div className="col-12">
                        <div className="row mx-0 card-details-footer">
                            <div className="col-12 sm-12 col-md-6">
                                <h6 className="mb-0">Prix article + Prix composant (si présent)</h6>
                            </div>
                            <div className="col-12 sm-12 col-md-3 pl-0">
                                <ul className="list-inline mb-0 d-flex align-items-center">
                                    <li className="list-inline-item">QTY:</li>
                                    <li className="list-inline-item">
                                        <div className="added-pricing d-flex align-items-center">
                                            <Form.Item className="design-update-item mb-0">
                                                {getFieldDecorator('quantity', {
                                                    initialValue: data.quantity
                                                })(<InputNumber onChange={this.onInputNumberChange} contentEditable={false}
                                                    step={1} min={data.warehouse_item.variant.units_per_set} />)}
                                            </Form.Item>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 sm-12 col-md-3 border-left-1">
                                <ul className="list-inline mb-0 text-right">
                                    <li className="list-inline-item pr-4">Prix total:</li>
                                    <li className="list-inline-item">{parseFloat(data.total_price).toFixed(2)}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.visible? <VerifierTarifPopup visible={this.state.visible} title={"Tarifs vérifiés !"} text={"Vérifié pour les tarifs et les prix sont à jour!"} onClick={() => { 
                            this.setState({ visible: false })
                            this.props.fetch()
                         }} />:""}
                {this.state.tarifNotapplyPopup? <VerifierTarifPopup visible={this.state.tarifNotapplyPopup} title={"Tarif non appliqué !"} text={"Non éligible au prix de groupe. Quantité non respectée."} onClick={() => {
                    this.setState({ tarifNotapplyPopup: false })
                    this.props.fetch()
                    }} />:""}

            </>

        );
    }
}

export default Form.create()(withTranslation('common')(SingleItemListCardDiscount));
