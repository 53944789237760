import React, {Component} from 'react';
import {Button, Drawer, Form, Input,message,Select} from "antd";
import {Image as Images} from '../../../components/Images'
import {withTranslation} from "react-i18next";
import {createWorkOrderEntity} from "../../../controller/API/salesOperationAPI";
import { GeneratetariffcoeffifientPdf } from '../../../controller/API/profileApi';

class TarifaireGenerateDrawer extends Component {
    state={
        loading:false
    }

    handleSubmit = (e) => {
        this.setState({loading:true})
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
                values={
                    ...values,
                    tariff_id:this.props?.tarifid
                }
            if (!err) {
                GeneratetariffcoeffifientPdf(values).then(response => {
                    message.success(response.message)
                    this.setState({loading:false})
                    this.props.onClose()
                }).catch(err => {
                    this.setState({loading:false})
                })
            }
        })
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const {t} = this.props;
        const {Option}=Select;
        return (
            <Drawer
                title='GÉNÉRER TARIF TTC PDF AU COEFFICIENT'
                placement="right"
                width="520px"
                destroyOnClose={true}
                closable={false}
                onClose={this.props.onClose}
                visible={this.props.visible}
                className="custom-drawer-update"
            >
                <div className="row custom-row">
                    <Button onClick={this.props.onClose}
                            className="close-header-icon w-auto flex-align-center-center px-3 position-absolute p-0 border-0">
                        <img src={Images.close_icon} alt="close icon" className="img-fluid"/>
                        <div>{t('close')}</div>
                    </Button>
                    <div className="col-12">
                        <Form onSubmit={this.handleSubmit} className="main-form">
                            <div className="row mx-0">
                                <div className='col-12'>
                                <Form.Item label={'Sales Coefficient :'}>
                                    {getFieldDecorator('coefficient', {
                                        rules: [{
                                            required: true,
                                            message: "Please input sales coefficient!"
                                        }],
                                    })(
                                        <Input/>,
                                    )}
                                </Form.Item>
                                </div>
                                <div className="drawer-footer w-100 position-absolute text-right bg-white px-3 py-2 border-top">
                        <Button  htmlType='submit' loading={this.state.loading} type="primary">
                            GÉNÉRER UN PDF
                        </Button>
                    </div>
                            </div>
                        </Form>
                    </div>
                
                </div>
            </Drawer>
        );
    }
}

export const CreateTarifarePDF = Form.create()(withTranslation('common')(TarifaireGenerateDrawer));