
import { distributorWorkorderSummaryGet } from '../../controller/API/salesOperationAPI';
import { AppDispatch, RootState } from '../store';
import { workOrderOneGet } from '../Reducer/WorkorderReducer';

export const WORKORDER_ONE_GET = 'WORKORDER_ONE_GET';

export const getOneWorkorder = (id: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await distributorWorkorderSummaryGet(id); // Wait for the promise to resolve
      dispatch(workOrderOneGet(response)); // Dispatch the action with the response data
    } catch (error: any) {
      // Handle the error, or log it if needed
      console.error(error);
    }
  };
};

export const mapWorkorderStateToProps = (state: RootState) => {
  return {
    workorder: state.current_workorder?.data
  };
};
