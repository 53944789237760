import React, {Component} from 'react';
import {Button, Form, Input, message, Spin} from "antd";
import {
    warehouseGet,
    warehouseVariantInventoryAdd,
    warehouseVariantInventoryGet, warehouseVariantInventoryRemove, warehouseVariantInventoryUpdate
} from "../../../controller/API/itemApi";
import {withTranslation} from "react-i18next";
import {VariantComponent} from "./VariantComponent";
import WarehouseFilter from "../../../filters/WarehouseFilter";
import {
    mapWarehouseDispatchToProps,
    mapWarehouseStateToProps
} from "../../../redux/Actions/WarehouseAction";
import {connect} from "react-redux";
import WarehouseInventoryInfo from "./WarehouseInventoryInfo";
import './StockInformation.css';

class StockInformationForm extends Component {

    state = {
        btnLoading: false,
        warehouseVariantInventory: [],
        loadingWarehouseVariantInventory: true,
        editInventory: false,
        editingInventory: null,
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({btnLoading: true});
                console.log(values)
                if (this.props.item) {
                    if (this.state.editInventory && this.state.editingInventory !== null){
                        const updateData = {
                            ...values,  variant: this.props.item.id,
                            warehouse: values.warehouse.key
                        };
                        warehouseVariantInventoryUpdate(this.state.editingInventory.id, updateData)
                            .then(response => {
                                message.success(`Updated warehouse item successfully`);
                                const warehouseVariantInventoryIndex = this.state.warehouseVariantInventory.findIndex(item => item.id === this.state.editingInventory.id);

                                this.state.warehouseVariantInventory.splice(warehouseVariantInventoryIndex, 1, response.data)
                                this.setState({ warehouseVariantInventory: this.state.warehouseVariantInventory })
                            })
                            .finally(() => {
                                this.editWarehouseItem(null);
                                this.setState({
                                    btnLoading: false,
                                })
                            })
                    }
                    else {
                        const warehouseExists = this.state.warehouseVariantInventory.findIndex(inventory => inventory.warehouse === values.warehouse) >= 0;
                        if (warehouseExists) {
                            message.warning('The warehouse inventory exists already, please update the existing warehouse')
                            this.setState({
                                btnLoading: false
                            })
                        }
                        else {
                            warehouseVariantInventoryAdd({ ...values, variant: this.props.item.id, }).then(
                                response => {
                                    message.success(`Added item to warehouse successfully`);
                                    this.setState({
                                        warehouseVariantInventory: [...this.state.warehouseVariantInventory, response.data]
                                    })
                                }
                            )
                            .catch((e) => {
                                message.error('An error occurred')
                            })
                            .finally(() => {
                                this.setState({
                                    btnLoading: false
                                })
                            })
                        }
                    }
                    // variantUpdate(this.props.item.id, values)
                    //     .then(response => {
                    //         message.success("Stocks updated successfully");
                    //         this.props.next(response.data);
                    //         this.setState({btnLoading: false});
                    //     }).catch(err => {
                    //     this.handleError(err);
                    //     this.setState({btnLoading: false});
                    // })
                }
            }
        });
    };
    handleError = (err) => {
        if (err.response) {
            Object.keys(err.response.data).forEach((i) => {
                message.error(`${i}:${err.response.data[i]}`)
            })
        } else {
            message.error("Something went wrong.")
        }
    };

    handleSaveAndContinue = (e) => {
        e.preventDefault();
        this.props.next(this.props.item);
    }
    componentDidMount() {
        warehouseGet().then(res => {
            this.props.getAllWarehouses(res.results)
        })
        warehouseVariantInventoryGet({variant: this.props.item.id}).then(res => {
            this.setState({
                warehouseVariantInventory: res.results,
                loadingWarehouseVariantInventory: false,
            })
        })
    }

    editWarehouseItem = (item) => {
        this.props.form.setFieldsValue(item === null ? null : {
            warehouse: { key: item.warehouse, label: this.props.warehouse_id_mapping[item.warehouse].name},
            in_stock: item.in_stock,
            in_transit: item.in_transit,
            in_stock_alert_threshold: item.in_stock_alert_threshold,
            available_alert_threshold: item.available_alert_threshold,

        })
        this.setState({
            editingInventory: item !== null ? item : null,
            editInventory: item !== null, // bool
        })
    }

    deleteWarehouseItem = (warehouseInventoryItem) => {
        this.editWarehouseItem(null)
        warehouseVariantInventoryRemove(warehouseInventoryItem.id).then(() => {
            message.success(`Removed warehouse item successfully`);
            this.setState({
                warehouseVariantInventory: this.state.warehouseVariantInventory.filter(item => item.id !== warehouseInventoryItem.id)
            })
        })
    }

    render() {
        const {t, item} = this.props;
        const {getFieldDecorator} = this.props.form;
        const {btnLoading, warehouseVariantInventory, loadingWarehouseVariantInventory, editingInventory} = this.state;
        const editWarehouseItem = this.editWarehouseItem;
        const deleteWarehouseItem = this.deleteWarehouseItem;
        const total_in_stock = warehouseVariantInventory.reduce((p, c) => p + c.in_stock, 0);
        const total_in_transit = warehouseVariantInventory.reduce((p, c) => p + c.in_transit, 0)
        const total_in_queued = warehouseVariantInventory.reduce((p, c) => p + c.in_queued, 0)
        const total_in_available_stock = warehouseVariantInventory.reduce((p, c) => p + c.in_available_stock, 0)

        return (
            <Form onSubmit={this.handleSubmit} className="main-form">
                {/* Totals */}
                <div className="d-flex flex-wrap mb-3">
                    {/* Total Stock */}
                    <div className="col-sm-12 col-12 col-md-12 col-lg-6">
                        <div className="row">
                            <div className="col-12">
                                <Form.Item label={t('in_actual_stock')}>
                                    <Input value={total_in_stock} disabled={true} className={'warehouse_inventory__total__input'}/>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    {/* Total In Transit*/}
                    <div className="col-sm-12 col-12 col-md-12 col-lg-6">
                        <div className="row">
                            <div className="col-12">
                                <Form.Item label={t('in_production')}>
                                    <Input value={total_in_transit} disabled={true} className={'warehouse_inventory__total__input'}/>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Warehouse Filter*/}
                <div className="col-sm-12 col-12 col-md-12 col-lg-12">
                    <div className="row">
                        <div className="col-12">
                            <Form.Item label={t('warehouse')}>
                                {getFieldDecorator('warehouse', {
                                    rules: [{
                                        required: true,
                                        message: `${t('input_your')} ${t('warehouse')}!`
                                    }],
                                })(
                                    <WarehouseFilter className={'mx-3'}/>
                                )}
                            </Form.Item>
                        </div>
                    </div>
                </div>

                {/* Add/Edit Inventory Section */}
                <div className="row mx-0 steps-main-row p-0">
                    <div className="col-sm-12 col-12 col-md-12 col-lg-6">
                        <div className="row">
                            <div className="col-12">
                                <Form.Item label={t('in_actual_stock')}>
                                    {getFieldDecorator('in_stock', {
                                        rules: [{
                                            required: true,
                                            message: `${t('input_your')} ${t('in_stock')}!`
                                        }],
                                    })(
                                        <Input autoFocus placeholder={t('articles_new')}/>,
                                    )}
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-12 col-md-12 col-lg-6">
                        <div className="row">
                            <div className="col-12">
                                <Form.Item label={t('in_production')}>
                                    {getFieldDecorator('in_transit', {
                                        rules: [{
                                            required: true,
                                            message: `${t('input_your')} ${t('intransit_new')}!`
                                        }],
                                    })(
                                        <Input placeholder={t('articles_new')}/>,
                                    )}
                                </Form.Item>
                            </div>
                        </div>

                    </div>

                    <div className="col-sm-12 col-12 col-md-12 col-lg-6">
                        <div className="row">
                            <div className="col-12">
                                <Form.Item label={t('in_stock_alert_threshold')}>
                                    {getFieldDecorator('in_stock_alert_threshold', {
                                        rules: [{
                                            required: true,
                                            message: `${t('input_your')} ${t('in_stock_alert_threshold')}!`
                                        }],
                                    })(
                                        <Input autoFocus placeholder={t('articles_new')}/>,
                                    )}
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-12 col-md-12 col-lg-6">
                        <div className="row">
                            <div className="col-12">
                                <Form.Item label={t('available_alert_threshold')}>
                                    {getFieldDecorator('available_alert_threshold', {
                                        rules: [{
                                            required: true,
                                            message: `${t('input_your')} ${t('available_alert_threshold')}!`
                                        }],
                                    })(
                                        <Input placeholder={t('articles_new')}/>,
                                    )}
                                </Form.Item>
                            </div>
                        </div>

                    </div>

                    <div className={'d-flex col-12 mb-4 justify-content-end'}>
                        <Button onClick={this.handleSubmit} type="primary">
                            {editingInventory !== null ? t('edit_warehouse_inventory') : t('add_warehouse_inventory')}
                        </Button>
                    </div>
                    {
                        loadingWarehouseVariantInventory ?
                        <div className={'d-flex justify-content-center w-100'}><Spin size={'large'}></Spin></div> :
                            <React.Fragment>
                                <div className={'d-flex justify-content-center w-100 col-12 flex-wrap'}>
                                    <div className={'d-flex w-100 address-add-row px-3 mt-0'} style={{backgroundColor: '#F4F5F8', border: 'none'}}>
                                        <div style={{ color: 'black', fontWeight: '800', width: '18%'}}>Entrepôt</div>
                                        <div style={{ color: 'black', fontWeight: '800', width: '18%'}}>{t('in_actual_stock')}</div>
                                        <div style={{ color: 'black', fontWeight: '800', width: '18%'}}>{t('on_hold')}</div>
                                        <div style={{ color: 'black', fontWeight: '800', width: '18%'}}>{t('in_available_stock')}</div>
                                        <div style={{ color: 'black', fontWeight: '800', width: '18%'}}>{t('in_production')}</div>
                                        <div style={{ color: 'black', fontWeight: '800', width: '10%'}}></div>
                                    </div>
                                </div>
                                <div className={'d-flex justify-content-center w-100 col-12 flex-wrap'}>
                                    {
                                        warehouseVariantInventory.map((inventory, key) =>
                                            <WarehouseInventoryInfo
                                                inventory={inventory}
                                                onEdit={() => editWarehouseItem(inventory)}
                                                onDelete={() => deleteWarehouseItem(inventory)}
                                                editable={true}
                                                key={key}/>
                                        )
                                    }
                                </div>
                                <div className={'d-flex justify-content-center w-100 col-12 flex-wrap'}>
                                    <div className={'d-flex w-100 address-add-row px-3 mt-0'} style={{borderColor: '#1655A2'}}>
                                        <div style={{ color: 'black', fontWeight: '800', width: '18%'}}>Totale</div>
                                        <div className={'px-3'} style={{ color: 'black', fontWeight: '800', width: '18%'}}>{total_in_stock}</div>
                                        <div className={'px-3'} style={{ color: 'black', fontWeight: '800', width: '18%'}}>{total_in_queued}</div>
                                        <div className={'px-3'} style={{ color: 'black', fontWeight: '800', width: '18%'}}>{total_in_available_stock}</div>
                                        <div className={'px-3'} style={{ color: 'black', fontWeight: '800', width: '18%'}}>{total_in_transit}</div>
                                        <div className={'px-3'} style={{ color: 'black', fontWeight: '800', width: '10%'}}></div>

                                    </div>
                                </div>
                            </React.Fragment>

                    }

                    <hr className={'w-100 mx-3 mb-4 mt-0'}/>
                    {
                        item.component_count > 0 ?
                        <VariantComponent variant={item}/> : ""
                    }
                </div>
                <div
                    className="drawer-footer-fixed steps-action steps-action-custom bg-white flex-align-center justify-content-between px-3 py-2">
                    <div>
                        <Button onClick={() => {
                            this.props.form.resetFields();
                        }}
                                className="font-weight-bold text-center text-blue border-0 bg-transparent text-capitalize p-0">{t('reset')}</Button>
                    </div>
                    <div>
                        <Button style={{marginRight: 15}} onClick={this.props.prev}>

                            {t('return')}
                        </Button>
                        <Button loading={btnLoading}
                                type="primary"
                                className="font-weight-bold text-center text-white text-uppercase"
                                onClick={this.handleSaveAndContinue}>
                            {t('save_continue')}
                        </Button>
                    </div>
                </div>
            </Form>
        );
    }
}

export const StockInformation = (Form.create(
    {
        mapPropsToFields(props) {
            if (props.item) {
                const {item} = props;
                return {
                    in_transit: Form.createFormField({
                        value: item.in_transit
                    }),
                    in_stock: Form.createFormField({
                        value: item.in_stock
                    }),

                }
            }
        }
    }
)(withTranslation('common')(connect(mapWarehouseStateToProps, mapWarehouseDispatchToProps)(StockInformationForm))));
