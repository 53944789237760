import React, { Component } from "react";
import { Button, Drawer, Spin } from "antd";
import { Image as Images } from "../Images";
import { getNotifications, getUserNotifications} from "../../controller/API/salesOperationAPI";
import { history } from "../../controller/history";
import { reverse } from "named-urls";
import { routes } from "../../controller/routes";
import { methods, profiles } from "../../controller/Global";
import { withTranslation } from "react-i18next";
import {BellOutlined} from "@ant-design/icons";

const moment = require('moment');

class AlertsDrawerCommon extends Component {
    state = {
        next: "",
        notifications: [],
        page: 1,
        loading: true,
    };

    componentDidMount() {
        if (this.props.userNotification) {
            this.handelUserNotification();
        } else {
            this.handleNotifications();
        }
    }

    handleNotifications() {
        getNotifications({ page: this.state.page }).then((response) => {
            this.setState((prevState) => {
                return {
                    notifications: [...prevState.notifications, ...response.data.results],
                    next: response.data.next,
                };
            });
            this.setState({ loading: false });
        });
    }

    handelUserNotification() {
        getUserNotifications({ page: this.state.page }).then((response) => {
            this.setState((prevState) => {
                return {
                    notifications: [...prevState.notifications, ...response.data.results],
                    next: response.data.next,
                };
            });
            this.setState({ loading: false });
        });
    }

    handleButton = () => {
        this.setState({ page: this.state.page + 1 }, () => {
            this.handleNotifications();
        });
    };

    renderNotificationContent = (userNotification, notification = {}) => {
        switch (true) {
            // Distributor notification message
          case userNotification && notification?.notification_type === "workorder_creation" && notification?.created_by?.role === "sales_person":
            return `[${notification?.created_by?.name}] a créé un bon de commande de distributeur [${notification?.user?.name}]`;

            case userNotification && notification?.notification_type !== "workorder_creation" && notification?.created_by?.role === "sales_person":
            return `[${notification?.created_by?.name}] a mis à jour une commande de distributeur [${notification?.created_by?.name}]`

          case userNotification && notification?.notification_type === "workorder_creation":
            return "a créé un bon de commande de distributeur";

          case userNotification && notification?.notification_type !== "workorder_creation":
            return `le statut du bon de commande est passé à ${this.props.t(notification?.workorder_status)}`;

          // Admin notification message  
          case !userNotification && notification?.variant_inventory_notification && notification?.notification_type === "variant_inventory_alert":
            return (<div>[{notification.sku}]<br />Alerte! Pas assez d'inventaire</div>);

          case !userNotification && notification?.workorder_notification && notification?.notification_type === "workorder_creation" && notification?.created_by?.role === "sales_person":
            return `[${notification?.created_by?.name}] a créé un bon de commande de distributeur [${notification?.name}]`;

          case !userNotification && notification?.workorder_notification && notification?.notification_type === "workorder_creation":
            return `[${notification.name}] a créé un bon de commande de distributeur`;

          case !userNotification && notification?.workorder_notification && notification?.notification_type === "workorder_update" && notification?.created_by?.role === "sales_person":
            return `[${notification?.created_by?.name}] a mis à jour une commande de distributeur [${notification?.name}]`;  

          case !userNotification && notification?.workorder_notification && notification?.notification_type === "workorder_update":
            return `[${notification.name}] a mis à jour une commande de distributeur`;
            
          case !userNotification && !notification?.workorder_notification:
            return `Nouveaux bons de Commande générés POUR CE DISTRIBUTEUR : ${notification.name}`;
          default:
            return null;
        }
      };

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <Drawer
                    title={"ALERTES"}
                    placement="right"
                    closable={false}
                    maskClosable={false}
                    onClose={this.props.onClose}
                    visible={this.props.visible}
                    getContainer={false}
                    width="520px"
                    destroyOnClose={true}
                    className="steps-drawer-div create-article-drawer"
                >
                    {this.state.loading ? (
                        <Spin className="spin-loading" />
                    ) : (
                        <>
                            <div className="row mx-0">
                                <Button
                                    onClick={this.props.onClose}
                                    className="close-header-icon w-auto px-3 flex-align-center-center position-absolute border-0"
                                >
                                    <img
                                        src={Images.close_icon}
                                        alt="close icon"
                                        className="img-fluid"
                                    />
                                    <div>{"FERMER"}</div>
                                </Button>
                                <div className="col-12 p-0">
                                    {this.state.notifications.map((i) => {
                                        return (
                                            <>
                                                <div className="row mx-0 alerts-row-main">
                                                    <div className="col-2">
                                                        <div className="notification-bell-card mx-auto">
                                                            <BellOutlined style={{color: 'white', fontSize: '150%'}}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-10">
                                                        {this.props.userNotification ?
                                                            <>
                                                                <h5 className="text-uppercase">
                                                                    {this.renderNotificationContent(true, i)}
                                                                </h5>
                                                                <div className="row alerts-inner-row">
                                                                    <div className="col-12">
                                                                        <ul className="list-inline">
                                                                            <li>
                                                                                <div className="row">
                                                                                    <div className="col-2">{i.workorder?.id}</div>
                                                                                    <div className="col-8">
                                                                                        <Button
                                                                                            className="col-8-workorder"
                                                                                            onClick={() =>
                                                                                                history.push(
                                                                                                    reverse(
                                                                                                        routes.dashboard.sales
                                                                                                            .work_order.method,
                                                                                                        {
                                                                                                            method: methods.view,
                                                                                                            type:
                                                                                                                profiles.distributor,
                                                                                                            id: i.workorder?.id,
                                                                                                        }
                                                                                                    )
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            VOIR Ce bon de commande
                                                                                        </Button>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <p className="mb-0">{moment(i.created).format("DD/MM/YYYY")}</p>
                                                            </>
                                                            :
                                                            <>
                                                            {i.variant_inventory_notification ? 
                                                                <>
                                                                <h5 className="text-uppercase">
                                                                    {this.renderNotificationContent(false, i)}
                                                                </h5>
                                                                <div className="row alerts-inner-row">
                                                                    <div className="col-12">
                                                                        <ul className="list-inline">
                                                                            <>
                                                                                <li>
                                                                                    <div className="row">
                                                                                        <div className="col-6">
                                                                                            <Button
                                                                                                className="col-6-inventory"
                                                                                                onClick={() =>
                                                                                                    history.push(
                                                                                                        reverse(
                                                                                                            routes.dashboard.warehouse.item,
                                                                                                            {
                                                                                                                method: methods.view,
                                                                                                                id: i.variant_id,
                                                                                                            }
                                                                                                        )
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                VOIR INVENTAIRE
                                                                                            </Button>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            </>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                    <p className="mb-0">{moment(i.created).format("DD/MM/YYYY")}</p>
                                                            </>
                                                                 
                                                                :
                                                                <>
                                                                <h5 className="text-uppercase">
                                                                    {this.renderNotificationContent(false, i)}
                                                                </h5>
                                                                <div className="row alerts-inner-row">
                                                                    <div className="col-12">
                                                                        <ul className="list-inline">
                                                                            <>
                                                                                {i.workorders && i.workorders.map((item) => (
                                                                                    <li>
                                                                                        <div className="row">
                                                                                            <div className="col-2">{item}</div>
                                                                                            <div className="col-8">
                                                                                                <Button
                                                                                                    className="col-8-workorder"
                                                                                                    onClick={() =>
                                                                                                        history.push(
                                                                                                            reverse(
                                                                                                                routes.dashboard.sales
                                                                                                                    .work_order.method,
                                                                                                                {
                                                                                                                    method: methods.view,
                                                                                                                    type:
                                                                                                                        profiles.distributor,
                                                                                                                    id: item,
                                                                                                                }
                                                                                                            )
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    VOIR Ce bon de commande
                                                                                                </Button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                ))}
                                                                            </>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                    <p className="mb-0">{moment(i.created).format("DD/MM/YYYY")}</p>
                                                            </>
                                                            }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                            </div>
                            {this.state.next && (
                                <button
                                    className="load-more-btn"
                                    onClick={() => {
                                        this.handleButton();
                                    }}
                                >
                                    Load more
                                </button>
                            )}
                        </>
                    )}
                </Drawer>
            </React.Fragment>
        );
    }
}

export default withTranslation('common')(AlertsDrawerCommon);
