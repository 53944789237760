import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from "./Reducer/RootReducer";
import thunk from "redux-thunk";

const middlewares = [thunk]

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(...middlewares),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;