import React, {Component} from 'react';
import {isAccessible} from "../../../../utils";
import WarehouseInventoryInfo from "../WarehouseInventoryInfo";

class InventoryInformation extends Component {
    calculateTotals = () => {
        let total_in_stock = 0;
        let total_in_transit = 0;
        let total_in_queued = 0;
        let total_in_available_stock = 0;

        for (let item of this.props.warehouseVariantInventory) {
            total_in_stock += item.in_stock;
            total_in_transit += item.in_transit;
            total_in_queued += item.in_queued;
            total_in_available_stock += item.in_available_stock;
        }
        return {
            total_in_stock,
            total_in_transit,
            total_in_queued,
            total_in_available_stock,
        }
    }
    render() {
        const {t, warehouseVariantInventory, variant} = this.props;
        let [total_in_stock,total_in_transit, total_in_queued, total_in_available_stock] = [0,0,0,0];

        if (warehouseVariantInventory !== undefined) {
            const {total_in_stock:_ts, total_in_transit: _tt, total_in_queued:_tq, total_in_available_stock: _tas} = this.calculateTotals();
            total_in_stock = _ts;
            total_in_transit = _tt;
            total_in_queued = _tq;
            total_in_available_stock = _tas;

        }
        else {
            const {in_stock:_ts, in_transit: _tt, total_in_queued:_tq, total_in_available_stock: _tas} = variant;
            total_in_stock = _ts;
            total_in_transit = _tt;
            total_in_queued = _tq;
            total_in_available_stock = _tas;
        }

        return (
            <div className="col-12 px-0">
                {isAccessible(['admin'], true) ?
                <div className="row card-details-general-row py-2">
                    <div className="col-lg-6 col-sm-12 col-12 col-md-6">
                        <ul className="list-inline mb-0 w-100">
                            <li className="list-inline-item width-100-tab w-50 m-0">{t('in_available_stock')} :</li>
                            <li className="list-inline-item width-100-tab w-50 m-0">
                                {isAccessible(['admin'], true) &&
                                    <i style={{color: `${total_in_available_stock > 0 ? 'green' : 'red'}`}}
                                       className="fa fa-circle"/>}
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-sm-12 col-12 col-md-6">
                        <ul className="list-inline mb-0 w-100">
                            <li className="list-inline-item width-100-tab w-50 m-0">{t('in_production')} :</li>
                            <li className="list-inline-item width-100-tab w-50 m-0">
                                {isAccessible(['admin'], true) &&
                                    <i style={{color: `${total_in_transit > 0 ? 'green' : 'red'}`}}
                                       className="fa fa-circle"/>}
                            </li>
                        </ul>
                    </div>
                </div>
                :
                <div className="row card-details-general-row py-2">
                    <ul className="list-inline mb-0 w-100">
                            <li className="list-inline-item width-100-tab w-50 m-0 px-2" style={{ color: 'black', fontWeight: '800'}}>Entrepôt</li>
                            <li className="list-inline-item width-100-tab w-50 m-0 px-2" style={{ color: 'black', fontWeight: '800'}}>{t('in_actual_stock')}</li>
                            <li className="list-inline-item width-100-tab w-50 m-0 px-2" style={{ color: 'black', fontWeight: '800'}}>{t('on_hold')}</li>
                            <li className="list-inline-item width-100-tab w-50 m-0 px-2" style={{ color: 'black', fontWeight: '800'}}>{t('in_available_stock')}</li>
                            <li className="list-inline-item width-100-tab w-50 m-0 px-2" style={{ color: 'black', fontWeight: '800'}}>{t('in_production')}</li>
                        </ul>
                </div>
        }
                {
                    isAccessible(['admin']) && warehouseVariantInventory && warehouseVariantInventory.map((inventory, key) => {
                        return (
                            <WarehouseInventoryInfo
                                inventory={inventory}
                                editable={false}
                                key={key}/>
                        )
                    })
                }
                {
                    isAccessible(['admin']) &&
                <div className={'d-flex w-100 address-add-row px-3 mt-0'} style={{borderColor: '#CADEF9'}}>
                <div className={'flex-grow-1'} style={{ color: 'black', fontWeight: '800', width: '18%'}}>Totale</div>
                <div className={'flex-grow-1 px-3'} style={{ color: 'black', fontWeight: '800', width: '18%'}}>{total_in_stock}</div>
                <div className={'flex-grow-1 px-3'} style={{ color: 'black', fontWeight: '800', width: '18%'}}>{total_in_queued}</div>
                <div className={'flex-grow-1 px-3'} style={{ color: 'black', fontWeight: '800', width: '18%'}}>{total_in_available_stock}</div>
                <div className={'flex-grow-1 px-3'} style={{ color: 'black', fontWeight: '800', width: '18%'}}>{total_in_transit}</div>
            </div>}
            </div>
        );
    }
}

export default InventoryInformation;