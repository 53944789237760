import { Button, Popover, Input, Table, Empty, Pagination, Tooltip, Icon } from 'antd';
import { reverse } from 'named-urls';
import { history } from '../../../controller/history';
import { Image as Images } from "../../Images";
import { withTranslation } from "react-i18next";

import React, { Component } from 'react';
import {connect} from "react-redux";
import {mapWarehouseDispatchToProps, mapWarehouseStateToProps} from "../../../redux/Actions/WarehouseAction";
import { routes } from '../../../controller/routes';
import { methods } from '../../../controller/Global';
import { ComponentGet, variantGet } from '../../../controller/API/itemApi';
import ComponentMain from './drawers/ComponentMain';
import ComponentFilter from './modal/ComponentFilter';
import { isAccessible } from "../../../utils";
import { UpdateComponentStock } from './modal/UpdateComponentStock';
import { UpdateComponentTransit } from './modal/UpdateComponentTransit';
import { WarningOutlined } from '@ant-design/icons';


const { Search } = Input;
const pagination = Pagination;
pagination.pageSize = 25;

class ComponentList extends Component {
    state = {
        loading: false,
        data: [],
        pagination: pagination,
        params: {},
        articleVisible: false,
        item: null,
        updateStock: false,
        updateTransit: false
    };    
    handleChange = (pagination, filters, sorter) => {
        let { params } = this.state;
        let symbol = sorter.order === 'descend' ? '-' : '';
        params = { ...params, page: pagination.current, ordering: `${symbol}${sorter.columnKey}` };
        this.setState({ params });

        this.fetch(params)
    };

    showUpdateStock = (visible, item = null, update = false) => {
        if (!visible && update)
            this.fetch(this.state.params);
        this.setState({
            updateStock: visible,
            item
        });
    };

    showUpdateTransit = (visible, item = null, update = false) => {
        if (!visible && update)
            this.fetch(this.state.params);
        this.setState({
            updateTransit: visible,
            item
        })
    };

    columns = () => {
        const { t } = this.props;
        return [
            {
                title: t('type_of_room'),
                dataIndex: 'piece_type',
                key: 'piece_type',
                sorter: false,
                render: (record) => {return t(record)}
            },
            {
                title: t('product_ref'),
                dataIndex: 'sku',
                key: 'sku',
                sorter: true,
            },
            {
                title: t('en_stock'),
                dataIndex: 'in_stock',
                key: 'in_stock',
                sorter: true,
            },
            {
                title: t('price'),
                dataIndex: 'price',
                key: 'price',
                sorter: true,
            },
            {
                title: t('in_actual_stock'),
                key: 'in_stock',
                sorter: true,
                render: (_, record) => {
                    let total_in_stock = record.warehouse_inventory_object
                        .map(warehouse_inventory => warehouse_inventory.in_stock)
                        .reduce((p, c) => p+c, 0);
                    let text = record.warehouse_inventory_object.map(
                        warehouse_inventory => (
                            <p className={'mb-0'}>
                                {`${this.props.warehouse_id_mapping[warehouse_inventory.warehouse].name}: ${warehouse_inventory.in_stock}`}
                                <span>( {<WarningOutlined />} at {warehouse_inventory.in_stock_alert_threshold})</span>
                            </p>)
                    );
                    return (isAccessible(['admin'], true) ?
                        <div className="flex-align-center">
                            <span className="mr-2"><i style={{ color: `${total_in_stock > 0 ? 'green' : 'red'}` }} className="fa fa-circle" />
                            </span>
                        </div>
                            :
                        <div className="flex-align-center">
                            <span className="mr-2">
                                <Tooltip placement="top" title={text} overlayClassName={'inventory_mangement__tooltip'}>
                                    <span className="mr-2">{total_in_stock}</span>
                                    <Icon type="info-circle" theme="twoTone" twoToneColor={total_in_stock > 0 ? "#52c41a" : "#fc0000"}/>
                                </Tooltip>
                                <Button onClick={(e) => {
                                    e.stopPropagation();
                                    this.showUpdateStock(true, record)
                                }}
                                        className="text-primary bg-transparent  shadow-none text-capitalize font-weight-normal rounded-0 border-0 p-0">
                                    {t('update')}
                                </Button>
                            </span>
                        </div>
                    )
                }
            },
            {
                title: t('on_hold'),
                key: 'in_queued',
                sorter: true,
                width:175,
                render: (_, record) => {
                    let total_in_queued = record.warehouse_inventory_object
                                                .map(warehouse_inventory => warehouse_inventory.in_queued)
                                                .reduce((p, c) => p+c, 0);
                    let text = record.warehouse_inventory_object.map(
                        warehouse_inventory => <p className={'mb-0'}>{`${this.props.warehouse_id_mapping[warehouse_inventory.warehouse].name}: ${warehouse_inventory.in_queued}`}</p>)
                    return (
                        <div className="flex-align-center">
                            <span className="mr-2">
                                <Tooltip placement="top" title={text} overlayClassName={'inventory_mangement__tooltip'}>
                                    <span className="mr-2">{total_in_queued}</span>
                                    <Icon type="info-circle" theme="twoTone" twoToneColor={total_in_queued > 0 ? "#52c41a" : "#fc0000"}/>
                                </Tooltip>
                            </span>
                        </div>
                    )
                }
            },
            {
                title: t('in_available_stock'),
                key: 'in_available_stock',
                sorter: true,
                render: (_, record) => {
                    let total_in_available_stock = record.warehouse_inventory_object
                                                .map(warehouse_inventory => warehouse_inventory.in_available_stock)
                                                .reduce((p, c) => p+c, 0);
                    let text = record.warehouse_inventory_object.map(
                        warehouse_inventory => (
                            <p className={'mb-0'}>
                                {`${this.props.warehouse_id_mapping[warehouse_inventory.warehouse].name}: ${warehouse_inventory.in_available_stock}`} 
                                <span>( {<WarningOutlined />} at {warehouse_inventory.available_alert_threshold})</span>
                            </p>
                        )
                    );
                    return (isAccessible(['admin'], true) ?
                        <div className="flex-align-center">
                            <span className="mr-2"><i style={{ color: `${total_in_available_stock > 0 ? 'green' : 'red'}` }} className="fa fa-circle" />
                            </span>
                        </div>
                        :
                        <div className="flex-align-center">
                            <span className="mr-2">
                                <Tooltip placement="top" title={text} overlayClassName={'inventory_mangement__tooltip'}>
                                    <span className="mr-2">{total_in_available_stock}</span>
                                    <Icon type="info-circle" theme="twoTone" twoToneColor={total_in_available_stock > 0 ? "#52c41a" : "#fc0000"}/>
                                </Tooltip>
                            </span>
                        </div>
                    )
                }
            },
            {
                title: t('in_production'),
                key: 'in_transit',
                sorter: true,
                render: (_, record) => {
                    let total_in_transit = record.warehouse_inventory_object
                                                .map(warehouse_inventory => warehouse_inventory.in_transit)
                                                .reduce((p, c) => p+c, 0);
                    let text = record.warehouse_inventory_object.map(
                        warehouse_inventory => <p className={'mb-0'}>{`${this.props.warehouse_id_mapping[warehouse_inventory.warehouse].name}: ${warehouse_inventory.in_transit}`}</p>)
                    return (isAccessible(['admin'], true) ?
                        <div className="flex-align-center">
                            <span className="mr-2"><i style={{ color: `${total_in_transit > 0 ? 'green' : 'red'}` }} className="fa fa-circle" />
                            </span>
                        </div>
                        :
                        <div className="flex-align-center">
                            <span className="mr-2">
                                <Tooltip placement="top" title={text} overlayClassName={'inventory_mangement__tooltip'}>
                                    <span className="mr-2">{total_in_transit}</span>
                                    <Icon type="info-circle" theme="twoTone" twoToneColor={total_in_transit > 0 ? "#52c41a" : "#fc0000"}/>
                                </Tooltip>
                                <Button onClick={(e) => {
                                    e.stopPropagation();
                                    this.showUpdateTransit(true, record)
                                }}
                                        className="text-primary bg-transparent  shadow-none text-capitalize font-weight-normal rounded-0 border-0 p-0">
                                    {t('update')}
                                </Button>
                            </span>
                        </div>
                    )
                }
            }
        ]
    };

    componentDidMount() {
        this.fetch();
    }


    fetch = (params = {}) => {
        this.setState({ loading: true, params: { ...params } });
        Object.keys(params).forEach(key => {
            if (params[key])
                params[key] = params[key].key ? params[key].key : params[key]
        });

        ComponentGet(params)
            .then(response => {
                let { pagination } = this.state;
                pagination.total = response.count;
                this.setState({ data: response.items, pagination, loading: false })
            })
    };

    getFilterData() {
        const { t } = this.props;
        let { params } = this.state
        if (Object.keys(params).length) {
            params = { 'delivery_date': `${params['start_date']} -  ${params['end_date']}` }
            return {
                params,
                'names': {
                    'delivery_date': t('delivery_date'),
                }
            }
        } else
            return { params }
    }

    showArticleVisible = (visible) => {
        this.setState({
            articleVisible: visible,
        });
    };

    render() {
        const { t } = this.props;
        const { pagination, loading, articleVisible, data, updateStock, updateTransit, item } = this.state;
        return (
            <div id="main-content" className="main-content-div float-right position-relative px-4 py-5 mt-5">

                <div className="row w-100 m-0 common-heading-details mb-3">
                    <div className="col-sm-6 col-md-7 col-12 p-0">
                        <div className="row mx-0 flex-align-center">
                            <h4 className="mb-0 font-weight-bold text-uppercase mr-4">{t('Components')}</h4>
                            <Button
                                type="primary"
                                onClick={() => this.showArticleVisible(true)}
                                className="plus-btn text-uppercase main-btn-tag flex-align-center text-white font-weight-bold pl-2 pr-3">
                                <img src={Images.plus_icon_white} />{t('create_a_component')}
                            </Button>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-5 col-12 p-0">
                        <div className="row mx-0 filter-row">
                            <div className="filter-main-div">
                                <Popover overlayClassName="filter-main-common inventory-filter"
                                    content={<ComponentFilter onFilter={this.fetch} type={"component"} />}
                                    title="" trigger="click">
                                    <Button className="ant-dropdown-link border-0"
                                        onClick={e => e.preventDefault()}>
                                        <img alt="filter icon" className="img-fluid"
                                            src={Images.filter_primary} />
                                        <span>Recherche avancée</span>
                                    </Button>
                                </Popover>
                            </div>
                            <div className="top-f-row-search">
                                <Search
                                    placeholder={t('search')}
                                    onSearch={(value) => this.fetch({ search: value })}
                                    className="ml-1 flex-align-center"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row w-100 m-0">
                    <div className="col-12 p-0">
                        <div className="listing-table when-pagination-true table-responsive container-table">
                            <Table
                                className="responsive-table table table-hover table-custom"
                                columns={this.columns()}
                                dataSource={data}
                                loading={loading}
                                pagination={pagination}
                                size="middle"
                                rowKey={row => row.id}
                                locale={{
                                    emptyText: (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description={t('no_data')} />)
                                }}
                                onChange={this.handleChange}
                                onRow={(record) => {
                                    return {
                                        onClick: () => {
                                            history.push(
                                                reverse(routes.dashboard.warehouse.componentItem, {
                                                    method: methods.view,
                                                    id: record.id,
                                                })
                                            )
                                        },
                                    };
                                }}
                            />
                        </div>
                    </div>
                </div>
                {updateStock &&
                    <UpdateComponentStock item={item} visible={updateStock}
                        onClose={(update) => this.showUpdateStock(false, null, update)} />}
                {updateTransit &&
                    <UpdateComponentTransit item={item} visible={updateTransit}
                        onClose={(update) => this.showUpdateTransit(false, null, update)} />}
                {articleVisible && <ComponentMain onUpdate={this.fetch} method={methods.create} visible={articleVisible}
                    onClose={() => this.showArticleVisible(false)} />}
            </div>
        );
    }
}

export default (withTranslation('common')(connect(mapWarehouseStateToProps, mapWarehouseDispatchToProps)(ComponentList)));
