import React, {Component} from 'react';
import {Button, Form, Input, message, Modal, Select} from "antd";
import {Image as Images} from "../../Images";
import {withTranslation} from "react-i18next";
import { sendTarifDocument } from '../../../controller/API/profileApi';
const {Option} = Select;

export class TarifaileEmailPopup extends Component {
    constructor(props){
        super(props)
        this.state = {
            loading: false,
            initialEmail:[],
            defaultEmail:[]

        };
    }

    handleSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({loading: true});
                const data={
                    ...values,
                    documents:this.props.emaildata
                }
                sendTarifDocument(data)
                    .then(response => {
                        this.setState({loading: false});
                        message.success("Successfully sent email")
                        this.props.getDocument()
                        this.props.onClose();
                    })
                    .catch(err => {
                        this.setState({loading: false});
                        this.props.onClose();
                    })
            }
        })
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const {loading} = this.state
        return (
            <Modal title={"Email"}
                   className="remove-default-close"
                   destroyOnClose={true}
                   visible={this.props.visible}
                   onCancel={this.props.onClose}
                   footer={<Button key="submit" onClick={this.handleSubmit} loading={loading}
                                   type="primary">VALIDER</Button>}
            >
                <div className="row mx-0">
                    <Button onClick={this.props.onClose}
                            className="close-header-icon w-auto flex-align-center-center px-3 position-absolute p-0 border-0">
                        <img src={Images.close_icon} alt="close icon" className="img-fluid"/>
                        <div>FERMER SANS VALIDER</div>
                    </Button>
                </div>
                <div className="text-capitalize p-4 row mx-0">
                <Form.Item label="Email">
                        {getFieldDecorator('email', {
                            rules: [{
                                required: true,
                                message: 'Email'
                            }],
                            initialValue: this.state.initialEmail || []
                        })(
                            <Select mode="tags" style={{width: '100%'}} placeholder="Email">
                                {this.state.defaultEmail.map((item, index) => {
                                    return <Option key={index} value={item.email}
                                                   className="workorder-email-select-opt name">
                                        {item.email} <br/>
                                        <span className='workorder-email-name'>{item.name}</span>
                                    </Option>
                                })}
                                {this.state.initialEmail?.map((email, index) => {
                                    return <Option key={index} value={email}
                                                   className="workorder-email-select-opt name">
                                        {email} <br/>
                                    </Option>
                                })}

                            </Select>
                        )}
                    </Form.Item>
                    {/* <Form.Item label="Email">
                    {getFieldDecorator('email', {
                                            rules: [{ required: true, message: 'please_input' }],
                                        })(
                                            <Input autoFocus placeholder={'email'} />,
                                        )}
                    </Form.Item> */}


                </div>
            </Modal>
        );
    }
}

export default Form.create()(withTranslation('common')(TarifaileEmailPopup));
