import { Button, Modal } from 'antd'
import React, { Component } from 'react'
import { Image } from '../../../Images'

export default class VerifierTarifPopup extends Component {
  render() {
    return (
        <Modal
        style={{ top: '26%' }}
        title={this.props.title}
        visible={this.props.visible}
        width={"40%"}
        closable={false}
        className="tabs-modal-exit profile-modal confirm-popup confirm-modal-main"
        footer={
            <Button key="submit" type="primary" onClick={this.props.onClick}>
                D'ACCORD
            </Button>
        }
    >
        <div className="row mx-0">
            <div className="check-icon float-left">
                <img src={Image.alert_popup_icon} alt="check-icon" className="img-fluid" />
            </div>
            <div className="check-text-details float-left">
                <div className="row mx-0">
                    <div className="col-12">
                        <h6
                            className="font-weight-bold mb-2">Important !</h6>
                        <p>{this.props.text}</p>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
    )
  }
}
