import React, { Component } from 'react';
import { Button, Empty, Form, message, Modal, Pagination, Select, Table, Tabs, Col, Checkbox, Row } from "antd";
import { withTranslation } from "react-i18next";
import TarifaireGroupSidebar from './TarifaireGroupSidebar';
import { CreateTarifarePDF } from "../../drawers/tarifare/TarifaireGenerateDrawer";
import { deleteDocument, GeneratetariffhtPdf, GeneratetariffttcPdf, getTariff, getTarifFaire, getTarifFaireOne, GettariffDocument } from '../../../controller/API/profileApi';
import { values } from 'lodash';
import SideBar from '../../SideBar';
import moment from 'moment';
import { getDateTime, Role } from '../../../utils';
import TarifInfoPopup from './TarifInfoPopup';
import TarifaileEmailPopup from './TarifaileEmailPopup';

const { TabPane } = Tabs;
const pagination = Pagination;
pagination.pageSize = 25;
const { Option } = Select;



class TarifaireGroupView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            invoices: [],
            group: null,
            Tabkey: "1",
            pagination: pagination,
            loading: false,
            checkStrictly: false,
            sidebarButton: "",
            open: false,
            selectedRowKeys: [],
            informationData: {},
            buttonLoading: false,
            visibleImp: false,
            current_tarif: null,
            tarifLoading: false,
            documentLoading: false,
            visibleEmailPopup: false,
            emaildata: []
        }
    }

    handleTarifOpen = (visible) => {
        this.setState({
            open: visible
        })
    }


    componentDidMount() {
        this.fetch(this.props.match.params.id)
    }
    getDocument = () => {
        this.setState({ documentLoading: true })
        let params = {
            tariff_id: this.props.match.params.id
        }
        GettariffDocument(params).then(res => { this.setState({ invoices: res.results, documentLoading: false, emaildata: [], selectedRowKeys:[] }) })

    }
    fetch = (id) => {
        this.setState({ loading: true });
        getTarifFaireOne(id).then((value) => {
            this.setState({ informationData: value, loading: false });
        }).catch((err) => { message.error(err) });
    };


    fetchTariff = (id) => {
        this.setState({ tarifLoading: true, data: [] })
        const params = {
            "group": id
        }
        getTariff(params).then(res => {
            let { pagination } = this.state;
            pagination.total = res.count;
            this.setState({ tarifLoading: false, pagination, data: res.results })
        })
    };

    handleonChange = (key) => {
        this.setState({ Tabkey: key })
        this.setState({ sidebarButton: "" })
        if (key === "2") {
            this.fetchTariff(this.props.match.params.id)
        }
        if (key === "3") {
            this.getDocument()
        }
    };
    columns = () => {
        const { t } = this.props;
        return [{
            title: t('name'), dataIndex: 'name', sorter: true, key: 'name',
        }, {
            title: t('family'), dataIndex: 'family.name', sorter: true, key: 'family',
        }, {
            title: t('category'), dataIndex: 'category.name', sorter: true, key: 'category',
        }, {
            title: t('reference'), dataIndex: 'variant_sku', sorter: true, key: 'reference',
        },
        {
            title: t('component_ref'), render: (record) => {
                return <React.Fragment>
                    <Button onClick={(e) => {
                        e.stopPropagation();
                        this.showImpModal(true, record.component)
                    }}
                        className="bg-transparent border-0 shadow-none p-0 text-primary font-weight-normal text-capitalize">{t('component_ref')}
                    </Button>
                </React.Fragment>
            }
        },
            // {
            //     title: t('component_ref'), dataIndex: 'component[0].component.sku', sorter: true, key: 'component',
            // },
            // {
            //     title: t('unit_price'), dataIndex: 'component[0].unit_price', sorter: true, key: 'component',
            // },
            // {
            //     title: t('group_price'), dataIndex: 'component[0].group_price', sorter: true, key: 'component',
            // }
        ];
    };

    showImpModal = (visible, data = null) => {
        this.setState({
            visibleImp: visible,
            current_tarif: data
        })
    }
    handleSidebarbutton = (btnName, key) => {
        this.setState({ Tabkey: key })
        this.setState({ sidebarButton: btnName })
        this.fetchTariff(this.props.match.params.id)
    }
    handleChange = (pagination, filters, sorter) => {
        this.setState({ loading: true })
        let symbol = sorter.order === 'descend' ? '-' : '';
        const params = { group: this.props.match.params.id, page: pagination.current, ordering: `${symbol}${sorter.columnKey}` };
        getTariff(params).then(res => { this.setState({ data: res.results, loading: false }) }).catch(err => message.error(err))
    };


    generatePdf = () => {
        this.setState({ buttonLoading: true })
        const { t } = this.props;
        const { sidebarButton, selectedRowKeys } = this.state;
        let data = {
            tariff_id: selectedRowKeys
        }
        if (selectedRowKeys?.length) {
            if (sidebarButton === t('generate_tariff_pdf')) {
                GeneratetariffhtPdf(data)
                    .then(res => {
                        message.success(res.message)
                        this.setState({ buttonLoading: false })
                    })
                    .catch((err) => this.setState({ buttonLoading: false }))

            } else if (sidebarButton === t('generate_price_pdf')) {
                GeneratetariffttcPdf(data)
                    .then(res => {
                        message.success(res.message)
                        this.setState({ buttonLoading: false })
                    })
                    .catch((err) => this.setState({ buttonLoading: false }))
            }
        } else {
            message.error("please select the row for create the pdf")
        }
    }

    render() {
        const columnsDocs = [{
            title: 'Imprimer',
            dataIndex: 'pdf',
            render: (pdf) => {
                return <a href={pdf} target="_blank" rel="noreferrer"
                    className="bg-transparent h-auto border-0 rounded-0 shadow-none p-0">
                    Voir PDF
                </a>
            }
        }, {
            title: 'Type De Document', dataIndex: 'type', key: 'name',
        }, {
            title: 'Date et l’heure', key: 'date_time', dataIndex: 'created', render: (data) => <>{moment(data).format('YYYY-MM-DD')} | {moment(data).format('h:mm')}</>
        }, {
            title: 'Email envoyé le', dataIndex: 'last_sent_email', render: last_sent_email => last_sent_email ? getDateTime(last_sent_email) : "",
        }, {
            title: 'Email', dataIndex: 'sent_email',
            //  render: () => <>
            //     <span>info@banner-interieur.fr, pierrelodolo@gmail.com</span>
            // </>,
        },];
        const { group, sidebarButton, selectedRowKeys, emaildata } = this.state;
        const { t } = this.props;
        // if (loading) {
        //     return <div className={'mt-5 p-0 text-center'}><Spin/></div>
        // }
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys: selectedRowKeys })
            },
        };
        const rowSelectionDocment = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                const id = selectedRows?.map(val => val.id)
                this.setState({ selectedRowKeys: selectedRowKeys, emaildata: id })
            },
            hideDefaultSelections: true,
            selections: [
                {
                    key: 'email',
                    text: 'Envoyer par email',
                    onSelect: changableRowKeys => {
                        this.setState({ visibleEmailPopup: true })
                    }
                },
                {
                    key: 'deletePdf',
                    text: 'Supprimer le PDF',
                    onSelect: changableRowKeys => {
                        deleteDocument({ documents: emaildata })
                            .then(res => {
                                this.getDocument()
                                message.success(res.data.message)
                            }).catch(err => message.error(err.response.data.non_field_errors[0]))
                    }
                },
            ],
        };
        return (<React.Fragment>
            <TarifaireGroupSidebar group={group} {...this.props} handleSidebarbutton={this.handleSidebarbutton}
                fetch={() => this.fetch(this.props.match.params.id)} />
            <div className="profile-summary-details-row">
                <div className="col-12 p-0">
                    <Tabs type="card" defaultActiveKey={"1"} activeKey={this.state.Tabkey}
                        onChange={this.handleonChange}>
                        <TabPane tab={t('summary')} key="1">
                            <div className="row summary-info-row mx-0">
                                <div className="col-12 px-0">
                                    <h6 className="text-uppercase font-weight-bold mb-4">{t('groupe_tarifaire_iNFORMATIONS')} </h6>
                                </div>
                                <div className="col-12 px-0">
                                    <div className="card border-0 position-relative mb-4">
                                        <div className="card-body">
                                            <div className="row mx-0">
                                                <div className="col-md-6 col-sm-6 col-12 col-lg-4">
                                                    <ul className="list-inline">
                                                        <li className="list-inline-item label-text-li">{t('group_name')} :
                                                        </li>
                                                        <li className="list-inline-item label-name-li">{this.state.informationData.name}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tab={t('tarifs')} key="2" >
                            <div className="row summary-info-row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="listing-table col-12 table-responsive when-pagination-true">
                                            <Table
                                                className="responsive-table table table-hover table-custom"
                                                columns={this.columns()}
                                                rowSelection={sidebarButton && rowSelection}
                                                dataSource={this.state.data}
                                                loading={this.state.tarifLoading}
                                                pagination={this.state.pagination}
                                                onChange={this.handleChange}
                                                rowKey={record => record.id}
                                                size="middle"
                                                locale={{
                                                    emptyText: (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                        description={t('no_data')} />)
                                                }}
                                            // onRow={(record) => {
                                            //     return {
                                            //         onClick: () => {
                                            //             history.push(reverse(routes.dashboard.profiles.tarifaire.method, {
                                            //                 method: methods.view,
                                            //                 id: "1234",
                                            //             }))
                                            //         },
                                            //     };
                                            // }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {sidebarButton === t('generate_tariff_pdf') || sidebarButton === t('generate_price_pdf') ?
                                <div
                                    className="steps-action steps-action-custom bg-white flex-align-center justify-content-end px-5">
                                    <div>
                                        <button type="button"
                                            className="ant-btn font-weight-bold text-center text-white text-uppercase ant-btn-primary"
                                            onClick={() => this.generatePdf()}
                                            loading={true}
                                        >
                                            <span>{t('generate_a_pdf')}</span></button>
                                    </div>
                                </div>
                                : sidebarButton === t('generate_pdf_tariff_including_cofficient') ? <div
                                    className="steps-action steps-action-custom bg-white flex-align-center justify-content-end px-5">
                                    <div>
                                        <button onClick={() => { this.state.selectedRowKeys?.length ? this.handleTarifOpen(true) : message.error("please select the row for create the pdf") }} type="button"
                                            className="ant-btn font-weight-bold text-center text-white text-uppercase ant-btn-primary">
                                            <span>CONTINUE</span></button>
                                    </div>
                                </div> : ""}
                            <CreateTarifarePDF visible={this.state.open} tarifid={this.state.selectedRowKeys}
                                onClose={() => this.handleTarifOpen(false)} />
                        </TabPane>
                        <TabPane tab={t('documents')} key="3">
                            <div className="row summary-info-row mx-0">
                                <div className="col-12 mb-3">
                                    <Form onSubmit={this.handleSubmit} className="main-form">
                                        <div className="row">
                                            <div className="col-12 text-right">
                                                <Select defaultValue="Filter" style={{ width: 175 }}>
                                                    <Option value="Filter">Filter</Option>
                                                </Select>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                                <div className="listing-table-second table-responsive col-12">
                                    <Table className="responsive-table table table-hover table-custom"
                                        dataSource={this.state.invoices}
                                        columns={columnsDocs}
                                        size="middle"
                                        rowSelection={rowSelectionDocment}
                                    />
                                </div>
                            </div>
                        </TabPane>
                    </Tabs>
                    <h5 className="header-tab-heading position-fixed text-uppercase font-weight-bold mb-0">{t('France_tariff')}</h5>
                </div>
            </div>
            <TarifInfoPopup visibleImp={this.state.visibleImp} onClose={() => this.showImpModal(false)} data={this.state.current_tarif} />
            {this.state.visibleEmailPopup && <TarifaileEmailPopup visible={this.state.visibleEmailPopup} onClose={() => this.setState({ visibleEmailPopup: false })} emaildata={this.state.emaildata} getDocument={() => this.getDocument()} />}
        </React.Fragment>);
    }
}

export default (withTranslation('common')(TarifaireGroupView));
