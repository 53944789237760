import React, {Component} from 'react';
import {Button, Spin} from "antd";
import {
    locationGroupItemGet,
} from "../../../../controller/API/salesOperationAPI";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {mapWarehouseDispatchToProps, mapWarehouseStateToProps} from "../../../../redux/Actions/WarehouseAction";
import GroupItemCard from "./GroupItemCard";


class GroupItemList extends Component {
    state = {
        itemListShow: false,
        items: [],
        params: {location_id: this.props.location.id},
        loading: true,
    };


    fetch = (params = {}) => {
        locationGroupItemGet(params)
            .then(response => {
                this.setState({items: response, loading: false})
            })

    };

    componentDidMount() {
        const {params} = this.state;
        this.fetch(params);

    }

    render() {
        const {items, loading, params} = this.state;
        const {t} = this.props;
        if (loading) {
            return <div className={'mt-5 p-0 text-center'}><Spin/></div>
        }
        return (
            <React.Fragment>
                {items.map((data, index) =>
                    <GroupItemCard
                        key={index}
                        groupCheckbox={true}
                        index={index}
                        data={data}
                        workorder={this.props.workorder}
                        fetch={() => this.fetch(params)}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default (withTranslation('common')(connect(mapWarehouseStateToProps, mapWarehouseDispatchToProps)(GroupItemList)));
