import React, { Component } from 'react'
import { Button, Empty, Form, message, Modal, Pagination, Select, Table, Tabs,Col,Checkbox, Row } from "antd";
import { withTranslation } from "react-i18next";

class TarifInfoPopup extends Component {
    columns = () => {
        const { t } = this.props;
        return [
        {
            title: t('component_ref'), dataIndex: 'component',  key: 'component',
        },
        {
            title: t('unit_price'), dataIndex: 'unit_price', key: 'component',
        },
        {
            title: t('group_price'), dataIndex: 'group_price',  key: 'component',
        }
    ];
};
    
  render() {
    const {t,data}=this.props;
    const newData = data?.length&&[...data]?.sort((a,b)=> a.component > b.component ? -1 : 1)
    return (
        <Modal
        title={t('component_ref')}
        visible={this.props.visibleImp}
        onCancel={this.props.onClose}
        width="50%"
        destroyOnClose={true}
        className="confirm-modal-main imp-modal-div"
        centered
        footer={[
            <Button onClick={this.props.onClose}>ok</Button>,
        ]}
    >
        <div className="row mx-0 p-4 w-100">
        <Table
            className="responsive-table table table-hover table-custom"
            columns={this.columns()}
            dataSource={newData}
            pagination={false}
            rowKey={record => record.id}
            size="middle"
            locale={{
                emptyText: (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={t('no_data')} />)
            }}
        
        />
        </div>
    </Modal>
    )
  }
}


export default (withTranslation('common')(TarifInfoPopup));