import React, {Component} from 'react';
import {Button, Form, Modal} from "antd";
import {withTranslation} from "react-i18next";
import {Image as Images} from "../../../../Images";
import ClientRankFilter from '../../../../../filters/clientRankFilter';
import { distributorProfileUpdate } from '../../../../../controller/API/profileApi';

class UpdateDistributorRankForm extends Component {

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                distributorProfileUpdate(this.props.distributorData?.id,  values)
                .then(() => {
                    this.props.onClose(true);
                }).catch(err => {
                    this.handleError(err)}
                )
            }
        })
    };

    componentDidUpdate(prevProps){
        if(prevProps.visible && !this.props.visible){
            this.props.form.resetFields();
        }
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {t, distributorData} = this.props;
        return (
            <Modal title={t('distributor_rank')}
                   visible={this.props.visible}
                   onCancel={()=>this.props.onClose(false)}
                   closable={false}
                   footer={[<Button key="submit" type="primary" onClick={this.handleSubmit}>
                       {t('update')}
                   </Button>]
                   }>
                <div className="row mx-0">
                    <Button onClick={()=>this.props.onClose(false)}
                            className="close-header-icon w-auto flex-align-center-center px-3 position-absolute p-0 border-0">
                        <img src={Images.close_icon} alt="close icon" className="img-fluid"/>
                        <div>FERMER</div>
                    </Button>
                </div>
                <div className="text-capitalize p-4 row mx-0">
                    <Form.Item label={t('distributor_rank')}>
                        {getFieldDecorator('client_rank', {
                            initialValue: distributorData?.client_rank
                        })(<ClientRankFilter />)}
                    </Form.Item>
                </div>
            </Modal>
        );
    }
}

export const UpdateDistributorRank = Form.create()(withTranslation('common')(UpdateDistributorRankForm));