import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface Warehouse {
  id: number; 
  address: string;
  name: string;
  phone: string;
  business_hours: string;
  city: string;
  country: string;
  zip_code: string;
}

interface WarehouseState {
  loading: boolean;
  warehouses: Warehouse[];
  warehouse_id_mapping: Record<string, Warehouse>;
}

const initialState: WarehouseState = {
  loading: true,
  warehouses: [],
  warehouse_id_mapping: {},
};

const warehouseSlice = createSlice({
  name: "warehouse",
  initialState,
  reducers: {
    warehouseAllGetSuccess: (state, action: PayloadAction<Warehouse[]>) => {
      state.loading = false;
      state.warehouses = action.payload;
      state.warehouse_id_mapping = action.payload.reduce((acc, warehouse) => {
        acc[warehouse.id] = warehouse;
        return acc;
      }, {} as Record<string, Warehouse>);
    },
  },
});

export const { warehouseAllGetSuccess } = warehouseSlice.actions;

export default warehouseSlice.reducer;