import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import store from './redux/store'
import {Provider} from "react-redux";
import * as Sentry from "@sentry/react";
import {getEnvValue} from "./controller/Environment";
import LogRocket from 'logrocket';

const REACT_APP_SENTRY_DSN = getEnvValue('REACT_APP_SENTRY_DSN');
const REACT_APP_ENVIRONMENT = getEnvValue('REACT_APP_ENVIRONMENT');
const REACT_APP_LOG_ROCKET_APP_ID = getEnvValue('REACT_APP_LOG_ROCKET_APP_ID');

if (!!REACT_APP_SENTRY_DSN && !!REACT_APP_ENVIRONMENT){
    Sentry.init({dsn: REACT_APP_SENTRY_DSN, environment: REACT_APP_ENVIRONMENT});
}
if (!!REACT_APP_LOG_ROCKET_APP_ID){
    LogRocket.init(REACT_APP_LOG_ROCKET_APP_ID);
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Provider store={store}><App /></Provider>);