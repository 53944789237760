import React, {Component} from 'react';
import {distributorProfileGet} from "../controller/API/profileApi";
import {Select, Spin} from "antd";
import {withTranslation} from "react-i18next";
import {isAccessible} from "../utils";

const {Option} = Select;

class DistributorProfileFilter extends Component {

  fetch = (params = {}) => {
    this.setState({loading: true});
    if(this.props.entity)
      params['entity'] = this.props.entity;
    params['ordering'] = 'client_name'
    distributorProfileGet(params)
      .then(response => {
        this.setState({values: response.data, loading: false})

        if (isAccessible('distributor')){
          const data = response.data[0];
          this.handleChange({key: JSON.stringify(data), label: data.client_name})
        }
      })
  };

  handleChange = (value) => {
    let org = JSON.parse(value.key);
    this.setState({value: { value, label: org.client_name }});
    this.props.onChange(org)
  };

  constructor(props) {
    super(props);
    this.state = {
      values: [],
      loading: false,
      value: this.props.value
    };
  }

  componentDidMount() {
    if (this.props.value) {
      this.handleChange(this.props.value)
    }
    if (isAccessible(['distributor'])){
      this.fetch()
    }
  }

  onSearch = (search) => {
    if(search.length >=3 )
      this.fetch({search})
  }

  render() {
    const {values, loading, value} = this.state;
    const {t} = this.props;
    return (
      <Select labelInValue
              onSearch={this.onSearch}
              showSearch
              optionFilterProp="children"
              value={value}
              // autoFocus={method===methods.create}
              showAction={["focus","click"]}
              onFocus={() => this.fetch()}
              notFoundContent={loading ? <Spin size="small"/> : null}
              onChange={this.handleChange}
              placeholder={t('select')}
              disabled={this.props.disabled}
      >
        {values.map((d, index) =>
          <Option key={`client_${d.id}`} value={JSON.stringify(d)}>{d.client_name}</Option>)
        }
      </Select>
    );
  }
}

export default (withTranslation('common')(DistributorProfileFilter));
