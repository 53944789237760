import React, {Component} from 'react';
import {Select, Spin} from "antd";
import {ComponentGet} from "../controller/API/itemApi";
import {Translation} from "react-i18next";

const {Option} = Select;

class ComponentFilter extends Component {
    state = {
        values: [],
        loading: false,
        value: this.props.value || []
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!nextProps.value) {
            return {value: []};
        } else if (typeof nextProps.value === "object") {
            return {value: nextProps.value}
        } else return null;
    }

    fetch = async (params = {}) => {
        this.setState({loading: true});
       await ComponentGet({variant_sku:this.props.sku})
            .then(res => {
                this.setState({values: res.items, loading: false})
            })
    };

    handleChange = (value) => {
        this.setState({value});
        this.props.onChange(value.key)
        this.props.unique_price_data(value)
    };

     componentDidMount() {
        if (this.props.value)
            this.handleChange(this.props.value)
    }

    render() {
        const {values, loading, value} = this.state;
        return (
            <Translation>
                {(t, {i18n}) =>
                    <Select labelInValue
                            showAction={["focus", "click"]}
                            value={value}
                            onSearch={(value) => this.fetch({search: value})}
                            showSearch
                            className={this.props.className || ""}
                            optionFilterProp="children"
                            onFocus={() => this.fetch()}
                            notFoundContent={loading ? <Spin size="small"/> : null}
                            onChange={(val) => this.handleChange(val)}
                            placeholder={t('select')}>
                        {values.map((d, index) => <Option key={`component_${index}`}
                                                          value={d.id}>{d.sku}</Option>)}
                    </Select>
                }
            </Translation>
        );
    }
}

export default ComponentFilter;
