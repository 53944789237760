import React, { Component } from 'react';
import { Button, Drawer, Form, Input, InputNumber, Select, Spin } from "antd";
import { Image as Images } from '../../components/Images'
import { withTranslation } from "react-i18next";
import { createWorkOrderEntity } from "../../controller/API/salesOperationAPI";
import { ComponentGet } from '../../controller/API/itemApi';
import { CreateComponentTariff, EditComponentTariff, getComponentTariff } from '../../controller/API/profileApi';
import { methods } from '../../controller/Global';
import ComponentFilter from '../../filters/componentFilter';

class TarifsGroupDrawer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            all_component: [],
            componentData: null,
            loading: false
        };
    }

    fetchComponent = (params = {}) => {
        ComponentGet(params).then((value) => {
            this.setState({ all_component: value.items, loading: false });

        })
    };

    componentChange = (componentData, price) => {
        this.setState({ componentData })
        var data = this.state.all_component.filter(val => val.id === componentData)
        this.props.form.setFieldsValue({
            "unique_price": data[0]?.price,
        });
    }

    setPrice = (value) => {
        var data = this?.props?.drawer_component_data?.filter(val => val.id === value.key)
        this.props.form.setFieldsValue({
            "unique_price": data[0]?.price,
        });

    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            values.additional_group_price = Number(values.additional_group_price);
            values.group_rate = Number(values.group_rate);
            values.group_price = Number(values.group_price);
            values.single_additional_price = Number(values.single_additional_price);
            values.unique_price = Number(values.unique_price);
            if (!err) {
                if (this.props?.data) {
                    EditComponentTariff(this.props.data.id, values).then(response => {
                        this.props.component(response.data)
                        this.props.onClose()
                    }).catch(err => {
                    })
                } else {
                    CreateComponentTariff(values).then(response => {
                        getComponentTariff(response.id).then((res) => {
                            this.props.component(res)
                        }).catch(err => {

                        })
                        this.props.onClose()
                    }).catch(err => {
                    })
                }

            }
        })

    };
    componentDidMount() {
        if (this.props?.data) {
            this.fetchComponent({ varient: this.props.varientID })
        }
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        const { t } = this.props;
        const { all_component, loading, componentData } = this.state;
        const { Option } = Select;
        return (
            <Drawer
                title={t('create_component')}
                placement="right"
                width="35%"
                destroyOnClose={true}
                closable={false}
                onClose={this.props.onClose}
                visible={this.props.visible}
            >
                <div className="row mx-0">
                    <Button onClick={this.props.onClose}
                        className="close-header-icon w-auto flex-align-center-center px-3 position-absolute p-0 border-0">
                        <img src={Images.close_icon} alt="close icon" className="img-fluid" />
                        <div>{t('close')}</div>
                    </Button>
                    <div className="col-12 px-0 ">
                        <Form onSubmit={this.handleSubmit} className="main-form">
                            <div className="row mx-0">
                                <Form.Item label={t('Components')}>
                                    {getFieldDecorator('component', {
                                        rules: [{
                                            required: true,
                                            message: `${t('please_input')} ${t('Components')}!`
                                        }],
                                    })(
                                        <ComponentFilter unique_price_data={(val) => this.setPrice(val)} varient={this.props.varientID} sku={this.props.sku} />
                                    )}
                                </Form.Item>
                                <Form.Item label={t('component_price')}>
                                    {getFieldDecorator('unique_price', {
                                        rules: [{
                                            required: true,
                                            message: `${t('please_input')} ${t('unique_price')}!`
                                        }],
                                    })(
                                        <Input
                                        />,
                                    )}
                                </Form.Item>
                                <Form.Item label={t('unit_price')}>
                                    {getFieldDecorator('unit_price', {
                                        rules: [{
                                            required: true,
                                            message: `${t('please_input')} ${t('unit_price')}!`
                                        }],
                                    })(
                                        <Input
                                        />,
                                    )}
                                </Form.Item>
                                <Form.Item label={t('group_rate')}>
                                    {getFieldDecorator('group_price', {
                                        rules: [{
                                            required: true,
                                            message: `${t('please_input')} ${t('group_rate')}!`
                                        }],
                                    })(
                                        <Input
                                            autoFocus
                                        />,
                                    )}
                                </Form.Item>
                                <Form.Item label={t('quantity_to_receive_the_group_price')}>
                                    {getFieldDecorator('group_rate', {
                                        rules: [{
                                            required: true,
                                            message: `${t('please_input')} ${t('quantity_to_receive_the_group_price')}!`
                                        }],
                                    })(
                                        <Input
                                        />,
                                    )}
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="drawer-footer w-100 position-absolute text-right bg-white px-3 py-2 border-top">
                        <Button onClick={this.handleSubmit} type="primary">
                            {t('add_a_component')}
                        </Button>
                    </div>
                </div>
            </Drawer>
        );
    }
}

export const CreateTarifs = Form.create({
    name: "tarifaire_com",
    mapPropsToFields(props) {
        if (props.data) {
            const group = props.data;
            return {
                component: group.component ? Form.createFormField({
                    value: {
                        key: group.component.id,
                        label: group.component.sku
                    },
                }) : "",
                unique_price: Form.createFormField({
                    value: group.unique_price,
                }),
                group_rate: Form.createFormField({
                    value: group.group_rate,
                }),
                group_price: Form.createFormField({
                    value: group.group_price,
                }),
                single_additional_price: Form.createFormField({
                    value: group.single_additional_price,
                }),
                additional_group_price: Form.createFormField({
                    value: group.additional_group_price,
                }),
                unit_price:Form.createFormField({
                    value:group.unit_price,
                })

            }
        }

    }
})(withTranslation('common')(TarifsGroupDrawer));