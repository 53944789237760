import {ConsoleSqlOutlined} from "@ant-design/icons";
import {Button, Form, InputNumber} from "antd";
import {T} from "antd/lib/upload/utils";
import {render} from "less";
import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {
    discount_coupon_component, distributorComponentUpdate,
} from "../../../../controller/API/salesOperationAPI";

class DiscountCard extends Component {
    onInputNumberChange = (val) => {
        const {item, data} = this.props;
        if (val) {
            distributorComponentUpdate(item.id, {
                quantity: val, workorder_item: item.workorder_item, component: item.component.id,
            }).then((res) => this.props.fetch());
        }
    };

    couponAPI = (id) => {
        discount_coupon_component({component: id}).then((response) => {
            this.props.fetch()
        });
    };

    render() {
        const {t, item, match} = this.props;
        const {getFieldDecorator} = this.props.form;
        return (<div className="col-12">
            <div className="row added-cart-item mx-0 mb-2 coupon-row p-0 bg-white"
                 style={{border: '1px solid #e0e0e0', borderRadius: '2px'}}>
                <div className="col-sm-3 col-12 added-cart-img">
                    <img className="img-fluid h-55" src={
                        item?.warehouse_component?.component_object?.images?.length &&
                        item?.warehouse_component?.component_object?.images[0].image
                    } alt="item"/>
                </div>
                <div className="col-12 col-sm-9 pt-2">
                    <div className="row">
                        <div style={{borderRight: '1px solid #f2f2f2', borderLeft: '1px solid #f2f2f2'}}
                             className="col-sm-6 pt-0 pb-0 col-12 added-cart-price bg-white px-3">
                            <h5>
                                {item?.warehouse_component?.component_object?.sku}
                            </h5>
                            <ul className="added-pricing justify-content-start list-inline">
                                <li className="list-inline-item">
                                    <small style={{color: "#646464"}}>{t(item?.warehouse_component?.component_object?.piece_type)}</small>
                                </li>
                                <li className="list-inline-item">
                    <span
                        className="d-inline-block"
                        style={{
                            width: "27px", height: "11px", backgroundColor: item?.warehouse_component?.component_object?.colour_code,
                        }}
                    ></span>
                                </li>
                                <li className="list-inline-item">
                                    <small style={{color: "#646464"}}>{item?.warehouse_component?.component_object?.colour}</small>
                                </li>
                            </ul>
                        </div>

                        <div className="col-sm-6 col-12 added-cart-list px-3">
                            <div className="row h-100 align-items-center">
                                <div className="col-12">
                                    <ul className="list-inline text-right">
                                        <li className="list-inline-item">
                                            QTY:
                                        </li>
                                        <li className="list-inline-item">
                                           {item.quantity}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default Form.create()(withTranslation("common")(DiscountCard));
